import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { confirmEmail } from "../../actions/authActions";
import Spinner from "../func-components/spinner-clone";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import logo from "../../images/splash.png";
import { Image } from "react-bootstrap";

class EmailConfirm extends Component {
  constructor() {
    super();
    this.state = {
      confirming: true,
    };
  }

  componentDidMount = () => {
    const { id } = this.props.match.params;
    /* const { teacher } = this.props.auth */

    const data = {
      id: id,
    };
    console.log("ID: ", id);
    axios
      .post(`/api/shared/confirm/${id}`, (data, this.props.history))
      .then((res) => {
        this.setState({ confirming: false });
        toast.success(<div style={{ fontSize: "2em" }}>{res.data.msg}</div>, {
          toastId: "emailconfirmed", // Prevent duplicate toasts
          onClick: () => window.close(), // Closes windows on click
          autoClose: false, // Prevents toast from auto closing
          theme: "dark",
        });
      })
      .then(() => {
        setTimeout(() => {
          this.props.history.push("/login");
        }, 5000);
      })
      .catch((err) => console.error(err));
    /* setTimeout(() => {
            this.props.confirmEmail(data, this.props.history)
            console.log(id)
            this.setState({ confirming: false })
            toast.success("test", {
                toastId: "emailconfirmed", // Prevent duplicate toasts
                onClick: () => window.close(), // Closes windows on click
                autoClose: false // Prevents toast from auto closing
            });
        }, 3000); */
  };

  render() {
    return (
      <div className="confirm">
        <ToastContainer
          position="top-center"
          hideProgressBar={false}
          closeOnClick
          draggable
        />
        <Image
          fluid
          src={logo}
          alt="splash-image"
          className="confirm-email-splash"
        />
      </div>
    );
  }
}
EmailConfirm.propTypes = {
  confirmEmail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { confirmEmail })(EmailConfirm);
