import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Card, Col, Row} from "react-bootstrap";
import {updatePushStatus} from "../../../actions/authActions";
import Backbar from "../../mobile/backbar";
import {useAlert} from "react-alert";
import axios from "axios";

function Notifications(props) {
  const { setrender, language, desktop } = props;
  const type = props.auth.teacher.typeOfUser;
  const email = props.auth.teacher.email;
  const alert = useAlert();

  const [isSubbed, setIsSubbed] = useState(null);
  const [user, setUser] = useState({});

  var OneSignal = window.OneSignal || [];

  let button = "";

  useEffect(() => {
    let isMounted = true;
    isMounted &&
    OneSignal.push(function () {
      OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
        console.log("is enabled: ", isEnabled);
        if (isEnabled) isMounted && setIsSubbed(true);
        else isMounted && setIsSubbed(false);
      });
    });
    let route = "/api/blaabla/get/type";
    if (type === "teacher") {
      route = "/api/teachers/get/teacher";
    } else if (type === "user") {
      route = "/api/users/get/user";
    } else {
      route = "/api/principals/get/principal";
    }
    axios
      .post(route, { email: email })
      .then((res) => {
        isMounted && setUser(res.data);
      })
      .catch((err) => console.log(err));

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSub = async (bool) => {
    /*if(shouldSub){
      await OneSignal.User.PushSubscription.optIn();
      alert.success(language.pushalert);
      setIsSubbed(shouldSub);
    } else {
      await OneSignal.User.PushSubscription.optOut();
      alert.success(language.pushalert);
      setIsSubbed(shouldSub);
    }*/
    OneSignal.push(function () {
      OneSignal.setSubscription(bool).then(() => {
        console.log(
          "User sub status is: ",
          bool ? "Subscribed" : "Not subscribed"
        );
        if (bool) {
          alert.success(language.pushalert);
          setIsSubbed(bool);
        } else {
          alert.error(language.pushoffalert);
          setIsSubbed(bool);
        }
      });
    });
  };

  const handleEmail = (bool) => {
    axios
      .post("/api/shared/set/sendemail", { email: email, emailBool: bool })
      .then((res) => {
        if (bool) {
          alert.success(language.emailalert);
        } else {
          alert.error(language.emailoffalert);
        }
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="">
      <Card
        /* className={desktop ? "mt-2" : ""} */ style={{
          marginLeft: desktop ? "" : "1em",
          marginRight: desktop ? "" : "1em",
          borderRadius: "20px",
          border: "none",
          boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
          height: "100%",
          marginTop: "20px",
        }}
      >
        <Backbar
          setrender={setrender}
          title={language.notifications}
          padding={true}
          desktop={desktop}
          noBack={desktop}
        />
        <Card.Body style={{ fontWeight: "500" }}>
          <Row>
            <Col className="align-label">
              <p className="toggle-label">{language.activatenoti}</p>
              <input
                checked={isSubbed}
                onChange={() => handleSub(!isSubbed)}
                type="checkbox"
                className="apple-switch"
              />
            </Col>
          </Row>
          <hr className="list-separator"></hr>
          <Row>
            <Col className="align-label">
              <p className="toggle-label">{language.activateEmailNoti}</p>
              <input
                checked={user?.settings?.sendEmail}
                onChange={() => handleEmail(!user?.settings?.sendEmail)}
                type="checkbox"
                className="apple-switch"
              />
            </Col>
          </Row>
          <hr className="list-separator"></hr>
        </Card.Body>
      </Card>
    </div>
  );
}
Notifications.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updatePushStatus: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { updatePushStatus })(Notifications);
