import React from "react";
import { Button, Modal } from "react-bootstrap";
import { default as CloseIcon } from "../users-only/icons/close_purple.svg";

const ConfirmModal = (props) => {
  const {
    header,
    content,
    execute,
    isConfirmOpen,
    handleCloseConfirmChange,
    id,
  } = props;
  return (
    <Modal
      show={isConfirmOpen}
      onHide={handleCloseConfirmChange}
      dialogClassName="apply-consent-modal"
      centered
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>{header}</Modal.Title>
        <button
          type="button"
          className="close"
          onClick={handleCloseConfirmChange}
          style={{ opacity: "1" }}
        >
          <span aria-hidden="true">
            <img
              src={CloseIcon}
              alt=""
              className="errorApplyCloseBtn"
              style={{ width: "1.3em" }}
            />
          </span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>{content}</p>
      </Modal.Body>
      <Modal.Footer className="px-0 py-4">
        <Button variant="custom" id={id} block onClick={execute}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
