import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ChosenForJob = (props) => {
  const { isOpen, handleCloseChange, tempJob } = props;

  return (
    <Modal show={isOpen} onHide={handleCloseChange} centered animation={false}>
      <Modal.Header closeButton>{tempJob.school}</Modal.Header>
      <Modal.Body>
        <p>Grattis, du har blivit vald som vikarie!</p>
        <p>Någon från {tempJob.school} tar kontakt inom kort.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="custom" onClick={handleCloseChange}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ChosenForJob;
