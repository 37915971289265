let data = [
    {
        name: "Svenska",
        name_fi: "Ruotsi"
    },
    {
        name: "Finska",
        name_fi: "Suomi"
    },
    {
        name: "Engelska",
        name_fi: "Englanti"
    },
    {
        name: "Danska",
        name_fi: "Tanska"
    },
    {
        name: "Norska",
        name_fi: "Norja"
    },
    {
        name: "Tyska",
        name_fi: "Saksa"
    },
    {
        name: "Franska",
        name_fi: "Ranska"
    },
    {
        name: "Ryska",
        name_fi: "Venäjä"
    },
]
export default data;
