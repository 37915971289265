import React from "react";
import {
  Image,
  Button,
  DropdownButton,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import "./customNavbar.css";
import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { default as Addteacher } from "./icons/add_teacher.svg";
import { default as Setup } from "./icons/setup.svg";
import { default as Vikariat } from "./icons/chalkboard-teacher-solid.svg";
import { default as Logout } from "./icons/log_out.svg";
import { default as Profile } from "./icons/profile_avatar.svg";
import DefaultAvatar from "../../images/avatar_default.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import { useBreakpoints } from "../func-components/mediaHooks";
import { hasEditedProfile } from "../../actions/authActions";
import axios from "axios";

const TopNavItems = (props) => {
  const { user, logoutTeacher, page, mobile } = props;
  const imgUrl = `${window.location.origin}/api/shared/image/`;
  var UserAvatar = imgUrl + user.image;
  const [language, setLanguage] = useState({});
  const [hasEdited, setHasEdited] = useState(false);
  const [moreSchools, setMoreSchools] = useState(false);
  const hideText = useBreakpoints().isTabletCeil;

  useEffect(() => {
      console.log(user)
    let isMounted = true;
    isMounted && getLang(props.auth.teacher);
    if (isMounted && user.typeOfUser === "user") {
      let promise = props.hasEditedProfile({ email: user.email });
      promise.then((data) => {
        let tempHasEdited = data.hasEdited;
        if (tempHasEdited === "undefined" || tempHasEdited === null) {
          tempHasEdited = false;
        }
        isMounted && setHasEdited(tempHasEdited);
      });
    }
      if (isMounted && props.auth.teacher.typeOfUser === "principal") {
          const data = {
              email: props.auth.teacher.email,
          };
          axios
              .post("/api/principals/get/principal", data)
              .then((res) => {
                  if (res.data.moreSchools.length >= 1) {
                      isMounted && setMoreSchools(true);
                  }
              })
              .catch((err) => console.error(err));
      }
    return () => (isMounted = false);
  }, []);

  function onLogoutClick() {
    logoutTeacher();
  }

  const vikariatButtonSwitch = (route) => {
    switch (decodeURIComponent(route)) {
      case "/app/jobs":
        return (
          <Button href="/jobs/new" variant="newjob-highlighted-2">
            <p
              style={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                marginBottom: "0",
              }}
            >
              <img
                alt=""
                src={Vikariat}
                className={`${hideText ? "" : "mr-2"} button-vikariat-svg`}
              />
              {hideText ? "" : language.newjob}
            </p>
          </Button>
        );
      default:
        return (
          <Button href="/jobs/new" variant="newjob-highlighted-2">
            <p
              style={{
                fontWeight: "600",
                marginBottom: "0",
                whiteSpace: "nowrap",
              }}
            >
              <img
                alt=""
                src={Vikariat}
                className={`${hideText ? "" : "mr-2"} button-vikariat-svg`}
              />
              {hideText ? "" : language.newjob}
            </p>
          </Button>
        );
    }
  };

  const isEmptyObject = (obj) => {
    if (typeof obj === "undefined" || typeof obj === "null") return true;
    return Object.keys(obj).length === 0;
  };

  const getLang = (user) => {
    if (user.siteLang === 2) {
      setLanguage(sv);
    } else if (user.siteLang === 1) {
      setLanguage(fi);
    } else {
      setLanguage(fi);
    }
  };

  const typeOfUserSwitch = (type) => {
    switch (type) {
      case "teacher":
        return language.nav_teachers;
      case "user":
        return language.substitute;
      case "principal":
        return language.principal;
      case "ledare":
        return language.ledare
      default:
        return "";
    }
  };
  const settingsSwitch = (type) => {
    switch (type) {
      case "principal":
        return "/principal/settings";
      default:
        return "/settings";
    }
  };

  const teacherButtonSwitch = (route) => {
    switch (decodeURIComponent(route)) {
      case "/app/teachers":
        return (
          <Button href="/teachers/create" variant="newjob-highlighted-2">
            <p
              style={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                marginBottom: "0",
              }}
            >
              <img
                alt=""
                src={Addteacher}
                className={`${hideText ? "" : "mr-2"} vikariat-svg`}
              />{" "}
              {hideText ? "" : language.addteacher}
            </p>
          </Button>
        );
      /* case '/teachers/create':
                return <Button href="/teachers/create" variant="newjob-highlighted">
                            <p style={{ fontWeight: "600", whiteSpace: "nowrap", marginBottom: "0"  }}>
                                <img alt="" src={Addteacher} className="mr-2 button-vikariat-svg" /> {language.addteacher}
                            </p>
                        </Button> */
      default:
        return (
          <Button href="/teachers/create" variant="newjob-highlighted-2">
            <p
              style={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                marginBottom: "0",
              }}
            >
              <img
                alt=""
                src={Addteacher}
                className={`${hideText ? "" : "mr-2"} button-vikariat-svg`}
              />{" "}
              {hideText ? "" : language.addteacher}
            </p>
          </Button>
        );
    }
  };

  return (
    <Row className="h-100">
      <IconContext.Provider value={{ style: { fontSize: "2.4em" } }}>
        {user.typeOfUser !== "user" ? (
          <Col className="my-auto">
            {vikariatButtonSwitch(window.location.pathname)}
          </Col>
        ) : (
          ""
        )}
        {user.typeOfUser === "principal" ? (
          <Col className="my-auto">
            {teacherButtonSwitch(window.location.pathname)}
          </Col>
        ) : (
          ""
        )}
      </IconContext.Provider>
      <hr className="vertical-hr-small"></hr>
      <Col className="my-auto mr-3">
        <DropdownButton
          variant="customDropdown"
          id="customDropdown"
          eventkey="3"
          title={
            <>
              <Image
                src={!isEmptyObject(user.image) ? UserAvatar : DefaultAvatar}
                height={60}
                width={60}
                id="img"
                style={{ borderRadius: "14px" }}
                alt=""
              />
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  marginLeft: "25px",
                  display: "inline-block",
                  verticalAlign: "middle",
                  textAlign: "left",
                }}
                className={mobile ? "mr-3" : "mr-5"}
              >
                {user.firstName}
                <br />
                <span
                  className="text-muted"
                  style={{ fontWeight: "400", fontSize: "14px" }}
                >
                  {typeOfUserSwitch(moreSchools ? "ledare" : user.typeOfUser)}
                </span>
                {!hasEdited && user.typeOfUser === "user" && (
                  <span className="other-check-profile active-mc">
                    <p>!</p>
                  </span>
                )}
              </span>
            </>
          }
        >
          <Dropdown.Item className="dd-item" eventKey="1" href="/profile">
            <img alt="" src={Profile} className="mr-3 dd-icon" />
            {language.profile}
            {!hasEdited && user.typeOfUser === "user" && (
              <span className="dditem-check-profile active-mc">
                <p>!</p>
              </span>
            )}
          </Dropdown.Item>
          {/* <Dropdown.Item className="dd-item" eventKey='2' href={settingsSwitch(user.typeOfUser)} >
                        <img alt="" src={Setup} className="mr-3 dd-icon" />
                            {language.settings}
                    </Dropdown.Item> */}
          <Dropdown.Item
            className="dd-item"
            eventKey="3"
            onClick={onLogoutClick}
          >
            <img alt="" src={Logout} className="mr-3 dd-icon" />
            {language.logout}
          </Dropdown.Item>
        </DropdownButton>
      </Col>
    </Row>
  );
};
TopNavItems.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hasEditedProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { hasEditedProfile })(TopNavItems);
