import React from "react";
import { Button, Modal, Col } from "react-bootstrap";
import { default as CloseIcon } from "../users-only/icons/close_purple.svg";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const DeleteSwipeModal = (props) => {
  const { language, isOpen, handleCloseChange, handleDeleteJob, data } = props;
  return (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={false}
      onHide={handleCloseChange}
      dialogClassName="apply-consent-modal modal-dialog-centered"
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>
          {language.fi ? "Pudota" : "Släng"}
        </Modal.Title>
        <button
          type="button"
          class="close"
          onClick={handleCloseChange}
          style={{ opacity: "1" }}
        >
          <span aria-hidden="true">
            <img
              src={CloseIcon}
              alt=""
              className="errorApplyCloseBtn"
              style={{ width: "1.3em" }}
            />
          </span>
          <span class="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Button
          variant="customDelete"
          block
          onClick={() => {
            handleDeleteJob(data.id);
            handleCloseChange();
          }}
        >
          {language.fi ? "Heitä sijaisuus" : "Släng vikariatet"}
        </Button>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
DeleteSwipeModal.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {})(withRouter(DeleteSwipeModal));
