import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createTeacher,
  logoutTeacher,
  sendInformationTeacher,
} from "../../actions/authActions";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import CustomNavbar from "../navigation/customNavbar";
import { faCamera, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import generator from "generate-password";
import DefaultAvatar from "../../images/avatar_default.png";
import fi from "../../texts/fi.json";
import sv from "../../texts/sv.json";
import ImageModal from "../profiles/imageModal";
import { default as CloseIcon } from "../users-only/icons/close_purple.svg";
import { default as PhoneIcon } from "../tempjob/icons/phone.svg";
import { default as MailIcon } from "../tempjob/icons/mail.svg";
import Sort from "../func-components/sort";
import { Typeahead } from "react-bootstrap-typeahead";
import GradeArray from "../../typeaheadData/gradeArray";
import { positions, withAlert } from "react-alert";

//styles import
import "../../styles/body-verticalCenter.css";
import "../../styles/addTeacher.css";
import axios from "axios";

class AddTeacher extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      education: "",
      grade: "",
      email: "",
      phone: "",
      subjects: [],
      image: null,
      file: null,
      errors: {},
      sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
      school: "",
      temp_pass: "",
      new_email: "",
      open: false,
      showloginInfo: false,
      linkId: "",
      language: {},
      crop: {
        aspect: 1 / 1,
        width: 50,
      },
      croppedImageUrl: null,
      croppedImageFile: null,
      isOpen: false,
      previewImage: null,
      grades: [],
      prin: null,
      otherSchools: [],
      chosenSchool: "",
    };
    this.inputFileRef = React.createRef();
  }

  componentDidMount() {
    /* const alert = this.props.alert
                alert.show("hello") */
    if (this.props.auth.teacher.typeOfUser !== "principal") {
      this.props.history.push("/");
    }
    const data = {
      email: this.props.auth.teacher.email,
    };

    this.getLang(this.props.auth.teacher);

    axios
      .post("/api/shared/getschool", data)
      .then((res) => {
        console.log("DATA: ", res.data);
        this.setState(
          {
            school: res.data.school,
            prin: res.data,
            otherSchools: res.data.moreSchools,
          },
          () => console.log("PRINSF: ", this.state.otherSchools)
        );
      })
      .catch((err) => console.log(err));
  }

  isEmptyObject = (obj) => {
    if (typeof obj === "undefined" || typeof obj === "null") return true;
    return Object.keys(obj).length === 0;
  };

  getLang = (user) => {
    if (user.siteLang === 2) {
      this.setState({ language: sv });
    } else if (user.siteLang === 1) {
      this.setState({ language: fi });
    } else {
      this.setState({ language: fi });
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    }
  }

  handleCloseChange = () => {
    this.setState({
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      grades: [],
    });
  };

  handleImageCloseChange = () => {
    this.setState({ isOpen: false });
  };

  showLogin = () => {
    this.setState({ showloginInfo: true });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    console.log(crop);
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        `${this.props.auth.teacher.id}.jpeg`
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        var file = new File([blob], fileName, { type: blob.type });
        this.setState({ croppedImageFile: file }, () =>
          console.log(this.state.croppedImageFile)
        );
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  handleImgUpload = (e) => {
    e.preventDefault();

    this.setState({
      image: this.state.croppedImageFile,
      file: this.state.croppedImageUrl,
      isOpen: false,
      croppedImageFile: null,
      croppedImageUrl: null,
      crop: { aspect: 1 / 1 },
    });
  };

  sendAsEmail = () => {
    const alert = this.props.alert;
    const s = this.state;
    alert.success(this.state.language.senttoteacher, {
      position: positions.BOTTOM_CENTER,
    });
    let data = {
      email: s.email,
      firstName: s.firstName,
      lastName: s.lastName,
      password: s.temp_pass,
    };
    this.props.sendInformationTeacher(data);
    this.props.history.push("/teachers");
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onImgChange = (e) => {
    this.setState({
      image: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
      isOpen: true,
    });
  };
  handleCheckbox = (e) => {
    const { subjects } = this.state;
    let check = e.target.checked;
    let checked_val = e.target.id;
    if (check) {
      subjects.push(checked_val);
    } else {
      var index = subjects.indexOf(checked_val);
      if (index > -1) {
        subjects.splice(index, 1);
      }
    }
  };

  handleFileClick = (e) => {
    this.inputFileRef.current.click();
  };

  showSidebar = () => {
    const { sidebar } = this.state;
    if (sidebar) {
      this.setState({ sidebar: false });
    } else if (!sidebar) {
      this.setState({ sidebar: true });
    }
  };

  addTeacherAndImage = () => {
    try {
      const { teacher } = this.props.auth;
      const {
        firstName,
        lastName,
        education,
        grade,
        email,
        phone,
        subjects,
        image,
        school,
        linkId,
        grades,
        chosenSchool,
      } = this.state;
      const password = generator.generate({ length: 10, numbers: true });
      this.setState({ temp_pass: password, new_email: email });

      /* Create form data with file and name */
      if (this.state.image) {
        const formData = new FormData();
        formData.append("img", this.state.image, this.state.image.name);
        /* Upload image to server */
        axios
          .post("/api/shared/image", formData, {
            onUploadProgress: (data) => {
              this.setState({
                uploadProgress: Math.round((100 * data.loaded) / data.total),
              });
            },
          })
          .then((res) => {
            this.setState(
              {
                file: res.data,
                uploadProgress: null,
                open: true,
                previewImage: res.data.file.filename,
              },
              () => {
                console.log(res.data);
                let thisSchool;
                chosenSchool
                  ? (thisSchool = chosenSchool)
                  : (thisSchool = school);
                const data = {
                  firstName,
                  lastName,
                  email,
                  school: thisSchool,
                  phone,
                  password,
                  grades,
                  filename: this.state.file.file.filename,
                };
                this.props.createTeacher(data);
              }
            );
          })
          .catch((err) => console.log(err));
      } else {
        let thisSchool;
        chosenSchool ? (thisSchool = chosenSchool) : (thisSchool = school);
        const data = {
          firstName,
          lastName,
          email,
          school: thisSchool,
          phone,
          password,
          grades,
        };
        /*  this.isKeysInObjectEmpty(data) */
        this.props.createTeacher(data);
        this.setState({ open: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  /*  isKeysInObjectEmpty = (obj) => {
             obj.map((item, index) => {
                 if(item === "" || typeof item === "undefined"){
                     return true
                 }
                 dont know what to do here, dont really need to do anything
             })
         } */

  onSubmit = (e) => {
    e.preventDefault();

    this.addTeacherAndImage();
  };

  onLogoutClick = () => {
    this.props.logoutTeacher();
  };

  render() {
    const imgUrl = `${window.location.origin}/api/shared/image/`;
    const { teacher } = this.props.auth;
    const subjects_list = [
      "Klasslärare",
      "Finska",
      "Engelska",
      "MoFi",
      "Teckning",
      "Musik",
      "Slöjd",
    ];
    const {
      open,
      firstName,
      errors,
      lastName,
      email,
      phone,
      temp_pass,
      showloginInfo,
      image,
      language,
      isOpen,
      crop,
      file,
      sidebar,
      prin,
      otherSchools,
    } = this.state;
    return (
      <div className="temp-body">
        <CustomNavbar
          userType={teacher.typeOfUser}
          sidebar={this.state.sidebar}
          showSidebar={this.showSidebar}
          page={language.nav_teachers}
          user={teacher}
          logoutTeacher={this.onLogoutClick}
          isAuth={this.props.auth.isAuthenticated}
        />
        <Sort
          header={language.addteacher}
          userType={teacher.typeOfUser}
          marginLeft={sidebar ? "215px" : "16px"}
          school={teacher.school}
        />
        <hr className="custom-hr mb-3"></hr>
        <div className={this.state.sidebar ? "main push-right" : "main"}>
          <Container fluid>
            <Card
              className="mt-3"
              style={{
                borderRadius: "10px",
                border: "none",
                boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
              }}
            >
              <Form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <Col>
                    <Card.Body className="mt-3">
                      <Card.Title>
                        <h4>{language.basicinfo}</h4>
                      </Card.Title>
                      {otherSchools && (
                        <Row>
                          <Col>
                            <Form.Group controlId="chosenSchool">
                              <Form.Control
                                as="select"
                                placeholder="chosenSchool"
                                onChange={this.onChange}
                                value={this.state.chosenSchool}
                              >
                                <option>{this.state.school}</option>
                                {otherSchools.map((ele) => (
                                  <option>{ele}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Form.Group controlId="firstName" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.firstname}
                          </Form.Label>
                          <Form.Control
                            className="custom-control"
                            onChange={this.onChange}
                            value={this.state.firstName}
                            type="text"
                          />
                          <span className="text-danger st">
                            {errors.firstName}
                          </span>
                        </Form.Group>
                        <Form.Group controlId="lastName" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.lastname}
                          </Form.Label>
                          <Form.Control
                            className="custom-control"
                            onChange={this.onChange}
                            value={this.state.lastName}
                            type="text"
                          />
                          <span className="text-danger st">
                            {errors.lastName}
                          </span>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group controlId="email" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.email}
                          </Form.Label>
                          <Form.Control
                            className="custom-control"
                            onChange={this.onChange}
                            value={this.state.email}
                            type="email"
                          />
                          <span className="text-danger st">{errors.email}</span>
                        </Form.Group>
                        <Form.Group controlId="phone" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.phone}
                          </Form.Label>
                          <Form.Control
                            className="custom-control"
                            onChange={this.onChange}
                            value={this.state.phone}
                            type="phone"
                          />
                          <span className="text-danger st">{errors.phone}</span>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group as={Col}>
                          <Form.Label className="lt cBold">
                            {language.grade}
                          </Form.Label>
                          <Typeahead
                            className="subject-picker"
                            placeholder={language.oneormore_standard}
                            id="grade-picker"
                            multiple
                            options={
                              language.fi ? GradeArray.fi : GradeArray.sv
                            }
                            onChange={(grades) => {
                              this.setState({ grades: grades.sort() });
                            }}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            className="mt-5"
                            type="submit"
                            variant="saveTeacher"
                            block
                          >
                            <FontAwesomeIcon
                              size="lg"
                              className="mr-2"
                              icon={faSave}
                            />
                            {language.saveteacher}
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Col>
                  <Col>
                    <Card.Body className="mt-3">
                      <Card.Title>
                        <h4>&#8203;</h4>
                      </Card.Title>
                      <Row>
                        <Form.Group as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.profilepic}
                          </Form.Label>
                          <Form.File
                            ref={this.inputFileRef}
                            className="text-muted"
                            label="Profilbild"
                            accept="image/*"
                            id="image"
                            type="file"
                            onChange={this.onImgChange}
                            style={{ display: "none" }}
                          />
                          <br />
                          <div className="image-preview">
                            <Image
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px",
                              }}
                              src={
                                this.state.file
                                  ? this.state.file
                                  : DefaultAvatar
                              }
                            />
                          </div>
                          <Button
                            onClick={this.handleFileClick}
                            className="mr-2"
                            variant="imageBtn"
                          >
                            <FontAwesomeIcon className="mr-3" icon={faCamera} />
                            {language.changepic}
                          </Button>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Modal
              show={open}
              /* shouldCloseOnOverlayClick={false} */
              backdrop="static"
              onHide={this.handleCloseChange}
              keyboard={false}
              dialogClassName="apply-consent-modal"
              animation={false}
            >
              <Modal.Header>
                <Modal.Title>{language.teacheradded}</Modal.Title>
                <button
                  type="button"
                  class="close"
                  onClick={this.handleCloseChange}
                  style={{ opacity: "1" }}
                >
                  <span aria-hidden="true">
                    <img src={CloseIcon} alt="" style={{ width: "1.3em" }} />
                  </span>
                  <span className="sr-only">Close</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <Card className="teacheradmin-block m-auto">
                  <Row>
                    <Card.Body
                      style={{ marginBottom: "0", paddingBottom: "0" }}
                    >
                      <Card.Img
                        rounded="true"
                        variant="left"
                        className="img-size"
                        src={
                          file
                            ? imgUrl + this.state.previewImage
                            : DefaultAvatar
                        }
                      />
                      <Card.Title
                        style={{
                          fontWeight: "600",
                          fontSize: "1.6em",
                          color: "#2e2f31",
                        }}
                        className="mb-0"
                      >
                        {firstName} {lastName}
                      </Card.Title>
                      {/* <Card.Text style={{ fontSize: "1.6em" }} className="mb-0">{temp_pass}</Card.Text> */}
                      {/* <Card.Text style={{ fontSize: "14px" }} className="mb-0">{teacher.grade || language.noinfo}</Card.Text> */}
                      {showloginInfo && (
                        <>
                          <Card.Text
                            style={{
                              fontSize: "2em",
                              color: "red",
                              fontWeight: "bold",
                            }}
                            className="mb-0"
                          >
                            {language.rememberteacher}
                          </Card.Text>
                          <Card.Text
                            style={{
                              fontSize: "1.6em",
                              color: "#555aa1",
                              fontWeight: "bold",
                            }}
                            className="mb-0"
                          >
                            {language.email}: {email}
                          </Card.Text>
                          <Card.Text
                            style={{
                              fontSize: "1.6em",
                              color: "#555aa1",
                              fontWeight: "bold",
                            }}
                            className="mb-0"
                          >
                            {language.password}: {temp_pass}
                          </Card.Text>
                        </>
                      )}
                    </Card.Body>
                  </Row>
                  <Row className="justify-content-sm-center">
                    <hr className="custom-hr-2" />
                  </Row>
                  <Row className="mx-auto">
                    <Card.Body
                      style={{
                        display: "inline-block",
                        textAlign: "left",
                        padding: "0px 0px 10px 0px",
                      }}
                    >
                      <Card.Text
                        style={{ fontSize: "1.4em", color: "#707070" }}
                        className="mb-0"
                      >
                        <img src={PhoneIcon} className="mr-3 ml-2" />
                        {phone}
                      </Card.Text>
                      <Card.Text
                        style={{ fontSize: "1.4em", color: "#707070" }}
                        className="mb-0"
                      >
                        <img src={MailIcon} className="mr-3 ml-2" />
                        {email}
                      </Card.Text>
                    </Card.Body>
                  </Row>
                </Card>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="custom" onClick={this.sendAsEmail} block>
                  {language.sendlogin}
                </Button>
                <Button variant="custom" onClick={this.showLogin} block>
                  {language.showlogin}
                </Button>
              </Modal.Footer>
            </Modal>
            <ImageModal
              isOpen={isOpen}
              handleCloseChange={this.handleImageCloseChange}
              img={file}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onCropComplete={this.onCropComplete}
              onCropChange={this.onCropChange}
              handleImgUpload={this.handleImgUpload}
              language={language}
            />
          </Container>
        </div>
      </div>
    );
  }
}

AddTeacher.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutTeacher: PropTypes.func.isRequired,
  createTeacher: PropTypes.func.isRequired,
  sendInformationTeacher: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  logoutTeacher,
  createTeacher,
  sendInformationTeacher,
})(withRouter(withAlert()(AddTeacher)));
