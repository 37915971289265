import React from "react";

const gdprFI = () => {
    return (
        <div>
            <section className="inline-body">
                <p>
                    <strong>
                        <span style={{fontSize: "18pt"}}>Tietosuojaseloste </span>
                        <br/>
                        <span style={{fontSize: "14pt"}}>
              Tietosuoja-asetus GDPR:n mukaisesti{" "}
            </span>
                        <br/>
                    </strong>
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        1. Rekisterinpitäjä
                        <br/>
                    </strong>
                </p>
                <p>Softigo Ab</p>
                <p>Y-tunnus:&nbsp;3336019-2&nbsp;</p>
                <p>&nbsp;&nbsp;</p>
                <p>
                    <strong>1.1 Yhteystiedot rekisteriä koskevissa asioissa</strong>
                </p>
                <p>
                    Softigo Ab
                    <br/>
                    Raastuvankatu 19, 65100 Vaasa
                </p>
                <p>Puh. +358 50 577 3854</p>
                <p>
                    Sähköposti: &nbsp;<a href="mailto:info@softigo.fi">info@softigo.fi</a>
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        2. Rekisteröityneet
                        <br/>
                    </strong>
                </p>
                <p>
                    Vikaaria -sovellukseen rekisteröityneet henkilöt
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        3. Henkilötietojen kerääminen
                        <br/>
                    </strong>
                </p>
                <p>
                    Rekisterinpitäjä kerää rekisteröidyn henkilötietoja tämän
                </p>
                <p>&nbsp;</p>
                <p>
                    ● rekisteröityessä tilin rekisterinpitäjän palveluun
                    <br/>

                    ● käyttäessä rekisterinpitäjän palveluita
                    <br/>

                    ● ottaessa yhteyttä rekisterinpitäjään sähköpostitse, puhelimitse tai rekisterinpitäjän
                    <br/>

                    sosiaalisen median kanavien kautta
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        4. Henkilötietojen jakaminen
                    </strong>
                </p>
                <p>
                    Rekisterinpitäjä käsittelee henkilötietoja ainoastaan seuraaviin ennalta määriteltyihin
                    tarkoituksiin: sovelluksen toiminnallisuus, diagnostiikka, asiakassuhteiden hallinta ja
                    rekisterinpitäjän palveluiden markkinointi. Henkilötietoja ei luovuteta kolmansille
                    osapuolille, paitsi jos se on tarpeen rekisterinpitäjän palvelujen tarjoamiseksi tai
                    lakisääteisten velvoitteiden täyttämiseksi. Kolmansia osapuolia ovat: koulut, esikoulut ja
                    päiväkodit, maksupalvelun tarjoajat ja viranomaiset lain niin vaatiessa.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        5. Asiakasrekisterin tietosisältö
                        <br/>
                    </strong>
                </p>
                <p>
                    Asiakasrekisteri sisältää seuraavat rekisteröinnin yhteydessä vaadittavat henkilötiedot:
                    etunimi, sukunimi, äidinkieli, osoite, sähköpostiosoite ja puhelinnumero. Rekisteri sisältää
                    rekisteröidyn profiilikuvan sekä tämän kaikki muut vapaaehtoisesti antamat tiedot
                    henkilökohtaisen profiilinsa laajentamiseksi.
                    Lisäksi rekisteri sisältää rekisteröidyn IP-osoitteen sekä tiedot koskien rekisteröidyn
                    liikenteestä rekisterinpitäjän verkkosivuilla.
                    Rekisterinpitäjä voi myös kerätä koulutus- ja ammattitietoja, todistuksia (ei
                    rikosrekisteriotteita tai verokortteja) ja suosituksia.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        5.1 Rikosrekisteriote
                        <br/>
                    </strong>
                </p>
                <p>
                    Turvallisen työympäristön varmistamiseksi rekisterinpitäjän palveluita käyttävät koulut,
                    esikoulut ja päiväkodit voivat pyytää sijaiselta lasten kanssa työskentelyyn liittyvän
                    rikosrekisteriotteen. Ote saa työsuhteen alkaessa olla korkeintaan 6 kuukautta vanha.
                    Rekisterinpitäjä ei koskaan pyydä, tallenna tai käsittele rekisteröidyn rikosrekisteriotetta.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>6. Henkilötietojen käyttö</strong>
                </p>
                <p>
                    Rekisterinpitäjä käyttää rekisteröidyn henkilötietoja:
                </p>
                <p>&nbsp;</p>
                <p>
                    ● omien palveluidensa tarjoamiseen ja parantamiseen
                    <br/>
                    ● rekisteröidyn tilin hallintaan
                    <br/>

                    ● sijaisten ja olennaisten sijaisuuksien yhteensovittamiseen
                    <br/>

                    ● kommunikoidakseen rekisteröidyn kanssa ja vastaamaan hänen kysymyksiinsä
                    <br/>

                    ● lähettääkseen uutiskirjeitä ja markkinointimateriaalia (rekisteröidyn suostumuksella)
                    <br/>

                    ● täyttääkseen rekisterinpitäjän lailliset velvoitteet
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7. Rekisteröidyn oikeudet</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.1 Tarkastusoikeus</strong>
                </p>
                <p>
                    Rekisteröidyllä on oikeus tarkastaa, mitä tietoja hänestä on rekisteröity.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.2 Oikeus tietojen oikaisuun</strong>
                </p>
                <p>
                    Rekisteröidyllä on oikeus pyytää virheellisten tai puutteellisten tietojen oikaisua.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.3 Vastustamisoikeus</strong>
                </p>
                <p>
                    Rekisteröity voi vastustaa henkilötietojensa käsittelyä, jos hän katsoo, että tietoja on
                    käsitelty voimassaolevan tietosuojalainsäädännön vastaisesti.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.4 Suoramarkkinointikielto</strong>
                </p>
                <p>
                    Rekisteröidyllä on oikeus kieltää henkilötietojensa käyttö suoramarkkinointiin.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.5 Oikeus tietojen poistamiseen</strong>
                </p>
                <p>
                    Rekisteröidyllä on oikeus pyytää tietojensa poistamista, mikäli tietojen säilyttäminen ei ole
                    tarpeellista. Rekisterinpitäjä käsittelee poistopyynnön, jonka jälkeen rekisterinpitäjä joko
                    poistaa tiedot tai antaa perustellun syyn niiden säilyttämiselle. Huomioithan, että
                    rekisterinpitäjällä voi olla lakisääteinen tai sopimusperusteinen velvollisuus olla poistamatta
                    pyydettyjä tietoja. Kirjanpitolain (2 luku, 10 §) mukaan rekisterinpitäjä on velvollinen
                    säilyttämään kirjanpitoaineisto vähintään 10 vuotta. Tämän vuoksi kirjanpitoaineistoa ei
                    voida poistaa ennen määräajan päättymistä.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.6 Suostumuksen peruuttaminen</strong>
                </p>
                <p>
                    Mikäli henkilötietojen käsittely perustuu ainoastaan suostumukseen eikä esimerkiksi
                    asiakassuhteeseen tai jäsenyyteen, rekisteröity voi peruuttaa suostumuksensa. Rekisteröity
                    voi valittaa päätöksestä tietosuojaviranomaiselle. Epäselvissä tapauksissa rekisteröidyllä on
                    oikeus rajoittaa henkilötietojensa käsittelyä, kunnes asia voidaan ratkaista.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.7 Oikeus tietojen poistamiseen</strong>
                </p>
                <p>
                    Rekisteröidyllä on oikeus tehdä valitus tietosuojaviranomaiselle, jos hän katsoo, että
                    rekisterinpitäjä on käsitellyt henkilötietoja voimassaolevan tietosuojalainsäädännön
                    vastaisesti. Tietosuojavaltuutetun toimiston yhteystiedot:
                    <br/>{" "}
                    <a href="http://www.tietosuoja.fi/fi/index/yhteystiedot.html">
                        www.tietosuoja.fi/fi/index/yhteystiedot.html
                    </a>
                    <br/>
                    Pyyntö lähetetään osoitteeseen: &nbsp;<a href="mailto:info@softigo.fi">info@softigo.fi</a>
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        8. Säännönmukaiset tietolähteet
                        <br/>
                    </strong>
                </p>
                <p>
                    Asiakastiedot saadaan pääsääntöisesti rekisteröidyltä itseltään rekisteröitymisen yhteydessä.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        9. Säännönmukaiset tietojen luovutukset
                        <br/>
                    </strong>
                </p>
                <p>
                    Säännöllisesti käytetyt palveluntarjoajat ovat Google Analytics, One Signal, Digital Ocean ja
                    Supabase. Palveluntarjoajat varmistavat noudattavansa tietosuojasäännöksiä.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        10. Tietojen käsittelyn kesto
                        <br/>
                    </strong>
                </p>
                <p>
                    Henkilötietoja käsitellään pääsääntöisesti niin kauan kuin rekisteröity on rekisteröityneenä.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        11. Henkilötietojen käsittelijät
                        <br/>
                    </strong>
                </p>
                <p>
                    Henkilötietoja käsittelee rekisterinpitäjä ja tämän työntekijät. Rekisterinpitäjä voi myös
                    osittain ulkoistaa henkilötietojen käsittelyn kolmannelle osapuolelle, jolloin rekisterinpitäjä
                    sopimusjärjestelyin varmistaa, että henkilötietoja käsitellään asianmukaisesti ja voimassa
                    olevan tietosuoja-asetuksen mukaisesti.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        12. Henkilötietojen siirto EU ulkopuolelle
                        <br/>
                    </strong>
                </p>
                <p>
                    Henkilötietoja ei siirretä EU tai Euroopan talousalueen ulkopuolelle.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        13. Automaattinen päätöksenteko ja profilointi
                        <br/>
                    </strong>
                </p>
                <p>
                    Henkilötietoja ei käytetä automaattiseen päätöksentekoon tai profilointiin.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>
                        14. Tietosuojakäytännön muutokset
                        <br/>
                    </strong>
                </p>
                <p>
                    Rekisterinpitäjä päivittää tietosuojakäytäntöä tarvittaessa. Rekisterinpitäjä suosittelee
                    tarkistamaan tietosuojaselosteen säännöllisesti pysyäkseen ajan tasalla mahdollisista
                    muutoksista.
                </p>
                <p>&nbsp;</p>
                <p>Vaasa 30.7.2024</p>
            </section>
        </div>
    );
};

export default gdprFI;
