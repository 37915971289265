import React from "react";
import { Button, Modal } from "react-bootstrap";
import { default as CloseIcon } from "../users-only/icons/close_purple.svg";
import { Link } from "react-router-dom";

const TeacherRegSuccess = (props) => {
  const { isOpen, handleCloseChange, language } = props;
  return language === "sv" ? (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={true}
      onHide={handleCloseChange}
      dialogClassName="apply-consent-modal"
      centered
      animation={false}
    >
      <Modal.Header style={{ display: "block" }}>
        <Modal.Title style={{ fontSize: "1.8em", textAlign: "center" }}>
          Registreringen lyckades.
        </Modal.Title>
        {/* <button type="button" class="close" onClick={handleCloseChange} style={{ opacity: "1" }}>
                        <span aria-hidden="true"><img src={CloseIcon} alt="" className="errorApplyCloseBtn" style={{ width: "1.3em" }} /></span>
                        <span class="sr-only">Close</span>
                    </button> */}
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Du flyttas automatiskt till inloggningen.
        </p>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Om inte,{" "}
          <Link className="forgot-link" to="/login">
            klicka här
          </Link>
        </p>
      </Modal.Body>
    </Modal>
  ) : (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={true}
      onHide={handleCloseChange}
      dialogClassName="apply-consent-modal"
      centered
    >
      <Modal.Header style={{ display: "block" }}>
        <Modal.Title style={{ fontSize: "1.8em", textAlign: "center" }}>
          Rekisteröityminen onnistui.
        </Modal.Title>
        {/* <button type="button" class="close" onClick={handleCloseChange} style={{ opacity: "1" }}>
                        <span aria-hidden="true"><img src={CloseIcon} alt="" className="errorApplyCloseBtn" style={{ width: "1.3em" }} /></span>
                        <span class="sr-only">Close</span>
                    </button> */}
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Sinut siirretään automaattisesti kirjautumiseen.
        </p>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Jos tämä ei tapahdu,{" "}
          <Link to="/login" className="forgot-link">
            klikkaa tästä
          </Link>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default TeacherRegSuccess;
