import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Button, Row, Card, Form, Col } from 'react-bootstrap';
import { BrowserRouter as Link } from "react-router-dom";
import Backbar from '../../mobile/backbar';
import { logoutTeacher, deleteUserAccount } from '../../../actions/authActions';
import { useAlert } from 'react-alert';


const Account = (props) => {
    const { setrender, language, desktop } = props;
    const alert = useAlert();

    const handleDeleteAcc = () => {
        let data = {
            email: props.auth.teacher.email
        }
        if (window.confirm(language.deleteaccmsg)) {
            props.deleteUserAccount(data);
            props.logoutTeacher();
        } else {
            alert.show("Ingen ändring!")
        }
    }

    return (
        <div>
            <Card className={desktop ? "mt-2" : ""} style={{ 
                marginLeft: "1em", 
                marginRight: "1em",
                borderRadius: "20px", 
                border: "none", 
                boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                height: "100%",
                marginBottom: "1rem",
                marginTop: "1em"
            }}>
            <Backbar setrender={setrender} title={language.account} padding={true} />
                <Card.Body>
                    <Form>
                        <Row className="f-col">
                            <Form.Group controlId="name" as={Col}>
                                <Form.Label className="text-muted">{language.sv ? "Namn" : "Nimi"}</Form.Label>
                                <Form.Control disabled className="custom-control" placeholder={props.auth.teacher.firstName + " " + props.auth.teacher.lastName} />
                            </Form.Group>
                        </Row>
                        <Row className="f-col">
                            <Form.Group controlId="email" as={Col}>
                                <Form.Label className="text-muted">{language.email}</Form.Label>
                                <Form.Control disabled className="custom-control" placeholder={props.auth.teacher.email} />
                            </Form.Group>
                        </Row>
                    </Form>
                    <Link to={{
                        pathname: "/resetpassword",
                        forgotProps: {
                            mobile: true
                        }
                    }}><Button variant="customSuccess" href="/resetpassword" block size="lg">{language.changepass}</Button></Link>
                    <Button variant="customDelete" className="mt-5" onClick={handleDeleteAcc} block size="lg">{language.deleteacc}</Button>
                </Card.Body>
            </Card>
        </div>
    )
}
Account.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    logoutTeacher: PropTypes.object.isRequired,
    deleteUserAccount: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})
export default connect(
    mapStateToProps, 
    { logoutTeacher, deleteUserAccount }
)(Account)
