import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {TeacherSidebarData, UserSidebarData} from "./sidebarData";
import axios from "axios";
import {default as AddVikariat} from "./icons/add-substitute.svg";
import {default as More} from "../tempjob/icons/dot_menu.svg";
import {default as More_active} from "../tempjob/icons/dot_menu_active.svg";
import {hasEditedProfile} from "../../actions/authActions";

/* TODO: texten under ikonerna på mobile är inte jämna!!!! */

const MobileNavbar = (props) => {
  const { page, user } = props;
  const [applicantCounter, setApplicantCounter] = useState(null);
  const [chosenCounter, setChosenCounter] = useState(null);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (user.typeOfUser === "user") {
      const data = {
        email: user.email,
      };
      axios
        .post("/api/users/temp/count", data)
        .then((res) => {
          !isCancelled && setApplicantCounter(res.data);
        })
        .catch((err) => console.error("Error trying to get temp count: ", err));
      axios
        .post("/api/users/getpersonaltemp", data)
        .then((res) => {
          let jobsChosenFor = 0;
          let jobs = res.data.activejobs;
          jobs.forEach((job, index) => {
            if (job.chosen === user.email) {
              jobsChosenFor++;
            }
          });
          !isCancelled && setChosenCounter(jobsChosenFor);
        })
        .catch((err) =>
          console.error("Error trying to get personal temp: ", err)
        );
      let promise = props.hasEditedProfile({ email: user.email });
      promise.then((data) => {
        let tempHasEdited = data.hasEdited;
        if (tempHasEdited === "undefined" || tempHasEdited === null) {
          tempHasEdited = false;
        }
        !isCancelled && setHasEdited(tempHasEdited);
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);
  const getLang = (fiTitle, svTitle) => {
    if (user.siteLang === 2) {
      return svTitle;
    } else if (user.siteLang === 1) {
      return fiTitle;
    } else {
      return fiTitle;
    }
  };
  return (
    <nav className="mobile-nav">
      {user.typeOfUser !== "user" && <div className="circle-container"></div>}
      {user.typeOfUser === "user" && (
        <>
          {UserSidebarData.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.path}
                className={
                  page === item.sv_title || page === item.fi_title
                    ? `mobile-nav-link mobile-active`
                    : `mobile-nav-link`
                }
              >
                {applicantCounter !== 0 &&
                  item.sv_title === "Lediga vikariat" && (
                    <span
                      className={
                        page === item.sv_title || page === item.fi_title
                          ? "mobile-app-count active-mc"
                          : "mobile-app-count"
                      }
                    >
                      <p>{applicantCounter}</p>
                    </span>
                  )}
                {chosenCounter !== 0 &&
                  item.sv_title === "Mina anmälningar" && (
                    <span
                      className={
                        page === item.sv_title || page === item.fi_title
                          ? "mobile-chosen-count active-mc"
                          : "mobile-chosen-count"
                      }
                    >
                      <p>{chosenCounter}</p>
                    </span>
                  )}
                <img
                  alt=""
                  src={
                    page === item.sv_title || page === item.fi_title
                      ? item.active_icon
                      : item.icon
                  }
                  className={`mobile-icons ${item.mobIconStyle}`} /* className={page === item.sv_title || page === item.fi_title ? `mobile-icons active-m-svg ${item.iconStyle}` : `mobile-icons ${item.iconStyle}`}  */
                />
                <span className="mobile-nav-text">
                  {getLang(item.fi_title, item.sv_title)}
                </span>
              </Link>
            );
          })}
          <Link
            to="/settings"
            className={
              page === "Inställningar" || page === "Asetukset"
                ? "mobile-nav-link mobile-active"
                : "mobile-nav-link"
            }
          >
            {!hasEdited && (
              <span className="mobile-check-profile active-mc">
                <p>!</p>
              </span>
            )}
            <img
              alt=""
              style={{ width: "7px", transform: "rotate(-90deg)" }}
              src={
                page === "Inställningar" || page === "Asetukset"
                  ? More_active
                  : More
              }
              className={
                page === "Inställningar" ? "mobile-icons" : "mobile-icons"
              }
            />
            <span className="mobile-nav-text">{getLang("Lisää", "Mer")}</span>
          </Link>
        </>
      )}
      {user.typeOfUser !== "user" && (
        <>
          {TeacherSidebarData.map((item, index) => {
            return item.title === "Anslagstavlan" ? (
              <Link
                key="anslagstavlan"
                to={item.path}
                className={
                  page === item.sv_title || page === item.fi_title
                    ? "mobile-nav-link mobile-active"
                    : "mobile-nav-link"
                }
              >
                <img
                  alt=""
                  src={
                    page === item.sv_title || page === item.fi_title
                      ? item.active_icon
                      : item.mobileicon
                  }
                  className={
                    page === item.sv_title || page === item.fi_title
                      ? `teacher-m-icons active-m-svg ${item.mobIconStyle}`
                      : `teacher-m-icons ${item.mobIconStyle}`
                  }
                />
                <span className="mobile-nav-text mt-1">
                  {getLang(item.fi_title, item.sv_title)}
                </span>
              </Link>
            ) : (
              ""
            );
          })}
          <Link
            key="addvikariat"
            to="/jobs/new"
            className={
              page === "Vikariat" || page === "Sijaisuudet"
                ? `mobile-nav-link mobile-active middle-btn`
                : `mobile-nav-link middle-btn`
            }
          >
            <img
              alt=""
              style={{ position: "fixed" }}
              src={AddVikariat}
              className={
                page === "Vikariat" || page === "Sijaisuudet"
                  ? "add-icon active-add-svg"
                  : "active-add-svg add-icon"
              }
            />
            <span style={{ marginTop: "86px" }} className="mobile-nav-text">
              {getLang("Lisää sijaisuus", "Skapa vikariat")}
            </span>
          </Link>
          {/* <Link key="addvikariat" to="/jobs/new" className={page === "Vikariat" || page === "Sijaisuudet" ? `mobile-nav-link mobile-active` : `mobile-nav-link`}>
                    <img alt="" src={AddVikariat} className={page === "Vikariat" || page === "Sijaisuudet" ? "add-icon active-add-svg" : "add-icon"} />
                    <span className="mobile-nav-text">{getLang("Lisää sijaisuus", "Lägg till vikariat")}</span>
                </Link> */}
          <Link
            to="/settings"
            className={
              page === "Inställningar" || page === "Asetukset"
                ? "mobile-nav-link mobile-active"
                : "mobile-nav-link"
            }
          >
            <img
              alt=""
              style={{ width: "7px", transform: "rotate(-90deg)" }}
              src={
                page === "Inställningar" || page === "Asetukset"
                  ? More_active
                  : More
              }
              className={
                page === "Inställningar" ? "mobile-icons" : "mobile-icons"
              }
            />
            <span className="mobile-nav-text">{getLang("Lisää", "Mer")}</span>
          </Link>
        </>
      )}
    </nav>
  );
};
MobileNavbar.propTypes = {
  hasEditedProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { hasEditedProfile })(MobileNavbar);
