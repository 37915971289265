import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {newPassword} from "../../actions/authActions";
import Container from "react-bootstrap/Container";
import logo from "../../images/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button, Form} from "react-bootstrap";
import Cookies from "js-cookie";
import {ToastContainer} from "react-toastify";
import LanguagePicker from "../func-components/languagePicker";

class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            password: "",
            password2: "",
            errors: {},
            mobile: false,
            language: Cookies.getJSON("_lang") ? Cookies.getJSON("_lang") : "SV",
        };
    }

    componentDidMount() {
        const handler = () => {
            let match = window.matchMedia("(max-width: 600px)").matches;
            if (match) {
                this.setState({mobile: true});
            } else {
                this.setState({mobile: false});
            }
        };
        handler();
        window.addEventListener("resize", handler);
    }

    handleLanguage = (e) => {
        this.setState({language: e.currentTarget.id}, () => {
            Cookies.set("_lang", this.state.language);
        });
    };

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.errors) {
            return {
                errors: nextProps.errors,
            };
        }
    }

    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    };

    async newPassCall(data) {
        const res = await this.props.newPassword(data, this.props.history);
        return res;
    }

    onSubmit = (e) => {
        e.preventDefault();
        const token = this.props.match.params.token;
        const data = {
            password: this.state.password,
            password2: this.state.password2,
            resetToken: token,
        };

        this.newPassCall(data).then((res) => {
            console.log("RES: ", res);
        });
        /* console.log(successStatus)
                    if (successStatus) {
                        let info;
                        if (this.state.language === "fi") {
                            info = <p style={{ fontSize: "1.8em" }}>Salasana vahvistettu</p>
                        } else {
                            info = <p style={{ fontSize: "1.8em" }}>Lösenordet bekräftat</p>
                        }
                        toast.success(info, {
                            toastId: "emailsent", // Prevent duplicate toasts
                            onClick: () => window.location.assign("/login"), // Closes windows on click
                            autoClose: false // Prevents toast from auto closing
                        });
                        setTimeout(() => {
                            window.location.assign("/login")
                        }, 5000);
                    } else {
                        console.log("error")
                    } */
    };

    render() {
        const {errors, mobile, language} = this.state;
        return (
            <div className="show-body">
                <div className="main">
                    <ToastContainer
                        position="top-center"
                        hideProgressBar={false}
                        closeOnClick
                        draggable
                    />
                    {mobile ? (
                        <>
                            <Container className="vertical-center">
                                <Row className="justify-content-sm-center">
                                    <img src={logo} alt="Logo" className="ml-5"/>
                                </Row>
                                <Row className="mt-3 justify-content-sm-center text-center">
                                    <Col md="4">
                                        <LanguagePicker
                                            language={language}
                                            handleLang={this.handleLanguage}
                                        />
                                    </Col>
                                </Row>
                                <Form noValidate onSubmit={this.onSubmit}>
                                    <Row className="justify-content-sm-center mt-5">
                                        <Col>
                                            <Form.Group>
                                                <Form.Control
                                                    onChange={this.onChange}
                                                    value={this.state.password}
                                                    error={errors.password}
                                                    id="password"
                                                    type="password"
                                                    placeholder={
                                                        language === "fi" ? "Salasana" : "Lösenord"
                                                    }
                                                    className={classnames("", {
                                                        invalid: errors.password,
                                                    })}
                                                />
                                                <span className="red-text">{errors.password}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-sm-center mt-2">
                                        <Col>
                                            <Form.Group>
                                                <Form.Control
                                                    onChange={this.onChange}
                                                    value={this.state.password2}
                                                    error={errors.password2}
                                                    id="password2"
                                                    type="password"
                                                    placeholder={
                                                        language === "fi"
                                                            ? "Vahvista salasana"
                                                            : "Bekräfta lösenord"
                                                    }
                                                    className={classnames("", {
                                                        invalid: errors.password2,
                                                    })}
                                                />
                                                <span className="text-danger lt">
                          {errors.password2}
                        </span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-sm-center mt-3">
                                        <Col>
                                            <Button variant="custom" size="lg" block type="submit">
                                                {language === "fi" ? "Vahvista" : "Bekräfta"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>
                        </>
                    ) : (
                        <>
                            <Container className="vertical-center">
                                <Row className="justify-content-sm-center">
                                    <img src={logo} alt="Logo" className="mr-3"/>
                                </Row>
                                <Row className="mt-3 justify-content-sm-center text-center">
                                    <Col md="4">
                                        <LanguagePicker
                                            language={language}
                                            handleLang={this.handleLanguage}
                                        />
                                    </Col>
                                </Row>
                                <Form noValidate onSubmit={this.onSubmit}>
                                    <Row className="justify-content-sm-center mt-5">
                                        <Col xs="4">
                                            <Form.Group>
                                                <Form.Control
                                                    onChange={this.onChange}
                                                    value={this.state.password}
                                                    error={errors.password}
                                                    id="password"
                                                    type="password"
                                                    placeholder={
                                                        language === "fi" ? "Salasana" : "Lösenord"
                                                    }
                                                    className={classnames("", {
                                                        invalid: errors.password,
                                                    })}
                                                />
                                                <span className="text-danger lt">
                          {errors.password}
                        </span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-sm-center mt-2">
                                        <Col xs="4">
                                            <Form.Group>
                                                <Form.Control
                                                    onChange={this.onChange}
                                                    value={this.state.password2}
                                                    error={errors.password2}
                                                    id="password2"
                                                    type="password"
                                                    placeholder={
                                                        language === "fi"
                                                            ? "Vahvista salasana"
                                                            : "Bekräfta lösenord"
                                                    }
                                                    className={classnames("", {
                                                        invalid: errors.password2,
                                                    })}
                                                />
                                                <span className="text-danger lt">
                          {errors.password2}
                        </span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-sm-center mt-3">
                                        <Col xs="4">
                                            <Button variant="custom" size="lg" block type="submit">
                                                {language === "fi" ? "Vahvista" : "Bekräfta"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    newPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {newPassword})(
    withRouter(ResetPassword)
);
