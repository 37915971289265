import React, {useState} from "react";
import {Container} from "react-bootstrap";
import "./legal.css";
import GdprFI from "./gdprFI";
import GdprSV from "./gdprSV";
import LanguagePicker from "../func-components/languagePicker";

const GDPR = (props) => {
    const {language} = props;
    const [lang, setLang] = useState("sv");

    const handleLanguage = (e) => {
        setLang(e.currentTarget.id);
    };
    return (
        <div>
            <Container
                className="d-flex flex-column align-items-center"
                fluid
                style={{paddingLeft: "0", paddingRight: "0", minHeight: "100%"}}
            >
                <div className="d-inline-block text-left ml-2">
                    <div className="mb-3 language-picker">
                        <LanguagePicker language={lang} handleLang={handleLanguage}/>
                    </div>
                    {lang === "fi" ? <GdprFI/> : <GdprSV/>}
                </div>
            </Container>
        </div>
    );
};

export default GDPR;
