import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import * as serviceWorker from "./serviceWorkerRegistration";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import Fallback from "./components/error-boundary/Fallback";
import {toast} from "react-toastify";

/* ReactDOM.render(
  <React.StrictMode>
    <HandleCache>
      {({ isLoading, isLatestVersionAvailable, clearCacheAndReload }) => {
        if (isLoading) return null;
        if (!isLoading && !isLatestVersionAvailable) {
          clearCacheAndReload();
        }

        return (
          <ErrorBoundary fallback={<Fallback />}>
            <App />
          </ErrorBoundary>
        );
      }}
    </HandleCache>
  </React.StrictMode>,
  document.getElementById("root")
); */

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<Fallback />}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
        toast.info("New version available, please close and reopen Vikaaria.", {
          toastId: "new-content"
        });
    } else {
      console.log("No updates found");
    }
  },
});
reportWebVitals(console.log);
