let data = [
  {
    name_swe: "Lärare småbarnsped.",
    name_fi: "Opettaja varhaiskasv.",
  },
  {
    name_swe: "Speciallärare småbarnsped.",
    name_fi: "Erityisopettaja varhaiskasv.",
  },
  {
    name_swe: "Barnskötare småbarnsped.",
    name_fi: "Lastenhoitaja varhaiskasv.",
  },
  {
    name_swe: "Assistent småbarnsped.",
    name_fi: "Avustaja varhaiskasv.",
  },
  {
    name_swe: "Klasslärare",
    name_fi: "Luokanopettaja",
  },
  {
    name_swe: "Speciallärare",
    name_fi: "Erityisopettaja",
  },
  {
    name_swe: "Specialklasslärare",
    name_fi: "Erityisluokanopettaja",
  },
  {
    name_swe: "Ämneslärare",
    name_fi: "Aineenopettaja",
  },
  {
    name_swe: "Timlärare",
    name_fi: "Tuntiopettaja",
  },
  {
    name_swe: "Elevhandledare",
    name_fi: "Oppilasohjaaja",
  },
  {
    name_swe: "Studiehandledare",
    name_fi: "Opinto-ohjaaja",
  },
  {
    name_swe: "Skolgångshandledare",
    name_fi: "Koulunkäynninohjaaja",
  },
  {
    name_swe: "Annan",
    name_fi: "Muu",
  },
];
export default data;
