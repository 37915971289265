let subjectData = [
    'Matematik', 
    'Modersmål och litteratur', 
    'Biologi', 
    'Gymnastik', 
    'Musik', 
    'Historia', 
    'Fysik', 
    'Geografi', 
    'Huslig ekonomi',
    'Religion', 
    'Finska', 
    'Slöjd',
    'Samhällslära', 
    'Kemi', 
    'Bildkonst',
    'Engelska',
    'Hälsokunskap',
    'Livsåskådningskunskap',
    'Omgivningslära'
]
export default subjectData;