export default {
    sv: String.raw`Som registrerad vikarie uppfattas du ha anknytning till pedagogik och
    undervisning. Genom att bekräfta anmälan, förbinder du dig att ta emot
    vikariatet om du väljs. Obefogade anmälningar eller annulleringar utan
    vägande skäl ger ett negativt intryck. Om du väljs, meddelas du i appen
    samt med notis och e-postmeddelande. Kom ihåg att aktivera dessa.
    Skolan är i kontakt med dig.`,
    
    fi: String.raw`Rekisteröityneenä sinun katsotaan olevan yhteydessä pedagogiikkaan ja
    opetukseen. Vahvistamalla ilmoittautumisen sitoudut vastaanottamaan
    sijaisuuden, jos sinut valitaan. Perusteettomat ilmoitukset tai peruutukset
    ilman hyvää syytä antavat negatiivisen vaikutelman. Jos sinut valitaan,
    sinulle ilmoitetaan sovelluksessa sekä vastaanotat ilmoituksen ja
    sähköpostiviestin. Muista aktivoida nämä. Koulu on sinuun yhteydessä.`
}