import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {addChosen, deleteFile, logoutTeacher, removeChosen, updateJobFiles,} from "../../actions/authActions";
import Spinner from "../func-components/spinner";
import CustomNavbar from "../navigation/customNavbar";
import {Button, Card, Col, Container, Form, Image, Row,} from "react-bootstrap";
import axios from "axios";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import Backbar from "../mobile/backbar";
import DefaultAvatar from "../../images/avatar_default.png";
import {withAlert} from "react-alert";
import ContactApplicant from "../func-components/contactApplicant";
import {toast, ToastContainer} from "react-toastify";
import RememberUser from "../buttons/RememberUser";

class ViewUserProfile extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            job: {},
            isLoading: true,
            sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
            file: [],
            files: [],
            refs: "",
            jobId: "",
            uploadedFiles: [],
            language: {},
            mobile: false,
            isOpen: false,
            loadingChosen: false,
        };
        this.inputFileRef = React.createRef();
    }

    componentDidMount() {
        const data = {
            email: this.props.match.params.email,
        };

        const data2 = {
            id: this.props.location.state.jobId,
            email: this.props.match.params.email,
        };

        this.getLang(this.props.auth.teacher);

        const handler = () => {
            let match = window.matchMedia("(max-width: 600px)").matches;
            if (match) {
                this.setState({mobile: true});
            } else {
                this.setState({mobile: false});
            }
        };
        handler();
        window.addEventListener("resize", handler);

        axios
            .post("/api/shared/get/user", data)
            .then((res) => {
                this.setState({user: res.data}, () => console.log(res.data));
            })
            .catch((err) => console.log(err));

        axios
            .post("/api/teachers/getspecific", data2)
            .then((res) => {
                this.setState({job: res.data, isLoading: false});
            })
            .catch((err) => console.log(err));
    }

    getLang = (user) => {
        if (user?.siteLang === 2) {
            this.setState({language: sv});
        } else if (user?.siteLang === 1) {
            this.setState({language: fi});
        } else {
            this.setState({language: fi});
        }
    };

    handleCloseChange = () => {
        this.setState({isOpen: false});
        this.props.history.push("/jobs/overview");
    };

    onLogoutClick = () => {
        this.props.logoutTeacher();
    };

    handleFileClick = (e) => {
        this.inputFileRef.current.click();
    };

    showSidebar = () => {
        const {sidebar} = this.state;
        if (sidebar) {
            this.setState({sidebar: false});
        } else if (!sidebar) {
            this.setState({sidebar: true});
        }
    };

    displayDates = (job) => {
        var options = {day: "numeric", month: "numeric"};
        if (
            new Date(job.startDate).toDateString() ===
            new Date(job.endDate).toDateString()
        ) {
            return new Date(job.startDate).toLocaleDateString("fi-FI");
        }
        return (
            this.removeDot(
                new Date(job.startDate).toLocaleDateString("fi-FI", options)
            ) +
            " - " +
            new Date(job.endDate).toLocaleDateString("fi-FI")
        );
    };

    goBack = () => {
        this.props.history.goBack();
    };

    onDownloadClick = (e) => {
        e.preventDefault();
        axios
            .get(`/api/shared/file/${e.target.id}`, {responseType: "blob"})
            .then((res) => {
                console.log(res);
                this.setState({fileDownloading: true}, () => {
                    const file = new Blob([res.data], {
                        type: res.data.type,
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                });
            })
            .then(() => {
                this.setState({fileDownloading: false});
                console.log("done");
            })
            .catch((err) => console.log(err));
    };

    handleFileUpload = () => {
        const formData = new FormData();
        this.state.files.forEach((file) => {
            formData.append("files", file, file.name);
        });
        axios
            .post("/api/shared/upload/files", formData)
            .then((res) => {
                this.setState({uploadedFiles: res.data.files}, () => {
                    let fileNameArr = [];
                    if (
                        this.state.job.files !== null ||
                        this.state.job.files.length > 0
                    ) {
                        this.state.job.files.forEach((file) => {
                            fileNameArr.push(file);
                        });
                    }
                    if (this.state.uploadedFiles !== null) {
                        this.state.uploadedFiles.forEach((file) => {
                            fileNameArr.push(file.filename);
                        });
                    }
                    const data = {
                        id: this.state.job.uniqueId,
                        files: fileNameArr,
                    };
                    console.log(data);
                    this.props.updateJobFiles(data);
                });
            })
            .catch((err) => console.error(err));
    };

    removeDot = (date) => {
        if (date[date.length - 1] === ".") {
            date = date.slice(0, -1);
            return date;
        }
        return date;
    };

    onFileChange = (e) => {
        /* this.setState({ doc: e.target.files[0], file: URL.createObjectURL(e.target.files[0]) }, () => console.log("doc: ", this.state.doc, "\n", "file: ", this.state.file)); */
        console.log(e.target.files);
        const files = e.target.files;
        let temp = [];
        for (let i = 0; i < files.length; i++) {
            temp.push(files[i]);
        }
        this.setState({files: temp}, () =>
            console.log("files: ", this.state.files)
        );
    };

    handleCoa = async (e) => {
        this.setState({loadingChosen: true});
        const alert = this.props.alert;

        const data = {
            email: this.state.user?.email,
            id: this.props.location.state.jobId,
        };
        const response = await this.props.addChosen(data, this.props.history);
        this.setState({loadingChosen: false});

        if (response.success) {
            this.setState({isOpen: true});
        } else {
            const content =
                this.props.auth.teacher.siteLang === 2
                    ? "Hoppsan! Vikarien valdes redan till ett annat vikariat."
                    : "Hups! Sijainen valittiin jo toiseen sijaisuuteen.";
            toast.warn(content);
            setTimeout(() => {
                window.location.href = "/jobs/overview";
            }, 20000);
        }

        /* if (window.confirm("Är du säker på ditt val?")) {
                                                                                                this.props.addChosen(data, this.props.history)
                                                                                            } else {
                                                                                                alert.info("Ok, ingen vikarie vald ännu.")
                                                                                            } */
    };

    handleRoa = (e) => {
        const alert = this.props.alert;

        const data = {
            email: this.state.user?.email,
            id: this.props.location.state.jobId,
        };
        if (window.confirm("Är du säker du vill ta bort din valda vikarie?")) {
            this.props.removeChosen(data, this.props.history);
        } else {
            alert.info("Ingen ändring");
        }
    };

    buttonVariant = () => {
        const {job, user} = this.state;

        if (job.chosen === user?.email) {
            return (
                <>
                    {/* <Button variant="customDelete" block onClick={this.handleRoa} className="ml-auto" style={{ fontSize: "14px" }}>{this.state.language.removeapp}</Button> */}
                </>
            );
        }
        if (
            job.chosen !== user?.email &&
            typeof job.chosen !== "undefined" &&
            job.chosen !== ""
        ) {
            return (
                <Button variant="secondary" block className="ml-auto" disabled>
                    {this.state.language.otherchosen}
                </Button>
            );
        }
        if (typeof job.chosen === "undefined" || job.chosen === "") {
            return (
                <Button
                    variant="custom"
                    block
                    className="ml-auto"
                    onClick={this.handleCoa}
                    disabled={this.state.loadingChosen}
                >
                    {this.state.loadingChosen ? (
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        this.state.language.choose
                    )}
                </Button>
            );
        }
    };

    render() {
        const {teacher} = this.props.auth;
        const {
            user,
            sidebar,
            isLoading,
            job,
            files,
            language,
            mobile,
            isOpen,
            loadingChosen,
        } = this.state;
        const imgUrl = `${window.location.origin}/api/shared/image/`;

        return (
            <div className="user-profile-body">
                <ToastContainer
                    position="top-center"
                    hideProgressBar={false}
                    closeOnClick
                    draggable
                />
                {isOpen && (
                    <ContactApplicant
                        isOpen={isOpen}
                        language={language}
                        handleCloseChange={this.handleCloseChange}
                        applicant={user}
                    />
                )}
                <CustomNavbar
                    userType={teacher.typeOfUser}
                    sidebar={sidebar}
                    showSidebar={this.showSidebar}
                    page={
                        teacher?.siteLang === 1 ? "Sijaisen profiili" : "Vikariens profil"
                    }
                    user={teacher}
                    logoutTeacher={this.onLogoutClick}
                    isAuth={this.props.auth.isAuthenticated}
                />
                {job.chosen === user?.email ? (
                    <Button variant="success" className="w-100 rounded-0" disabled block>
                        {language.chosenbanner}
                    </Button>
                ) : (
                    ""
                )}
                <div className={sidebar ? "viewjob-main push-right" : "viewjob-main"}>
                    {!isLoading ? (
                        <Container fluid>
                            <Card
                                className="mt-3"
                                style={{
                                    borderRadius: "10px",
                                    border: "none",
                                    boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                    marginBottom: mobile ? "120px" : "1em",
                                }}
                            >
                                <Backbar
                                    title={language.back}
                                    padding={true}
                                    goback={this.goBack}
                                    sticky={mobile}
                                    desktop={!mobile}
                                    noBack={false}
                                />
                                <Form>
                                    <Row className="f-col">
                                        <Col>
                                            <Card.Body>
                                                <Card.Title className="d-flex">
                                                    <h4>{language.basicinfo}</h4>
                                                    <RememberUser
                                                        rememberUserEmail={user.email}
                                                        email={teacher.email}
                                                    />
                                                </Card.Title>
                                                <Row className="f-col">
                                                    <Form.Group controlId="firstName" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.firstname}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.firstName}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="lastName" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.lastname}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.lastName}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="f-col">
                                                    <Form.Group controlId="status" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.status}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.status}
                                                        />
                                                    </Form.Group>
                                                    {(user?.status === "Studerande" ||
                                                        user?.status === "Opiskelija") && (
                                                        <Form.Group controlId="occugroup" as={Col}>
                                                            <Form.Label className="text-muted lt cBold">
                                                                {language.schoolprofile}
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                className="custom-control"
                                                                placeholder={user?.occuGroup?.school}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                    {user?.status !== "Studerande" &&
                                                        user?.status !== "Opiskelija" && (
                                                            <Form.Group controlId="types" as={Col}>
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.qualifications}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    as="textarea"
                                                                    className="custom-control"
                                                                    placeholder={user?.qualifications?.map(
                                                                        (qualification, index) =>
                                                                            (index ? " " : "") +
                                                                            (language.fi
                                                                                ? qualification.name_fi
                                                                                : qualification.name_swe)
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                </Row>
                                                {user?.status !== "Studerande" &&
                                                user?.status !== "Opiskelija" ? (
                                                    <>
                                                        {/* <Row className="f-col">
                                                      <Form.Group controlId="occugroup" as={Col}>
                                                          <Form.Label className="text-muted lt cBold">{language.occugroup}</Form.Label>
                                                          <Form.Control disabled className="custom-control" placeholder={user?.teacherType} />
                                                      </Form.Group>
                                                  </Row> */}
                                                        <Row className="f-col">
                                                            <Form.Group controlId="education" as={Col}>
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.degree2}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={user?.education}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="exYear" as={Col}>
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.yearofdegree}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={user?.examinationYear}
                                                                />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="f-col">
                                                            <Form.Group controlId="occugroup" as={Col}>
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.schoolprofile}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={user?.occuGroup?.school}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="education" as={Col}>
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.studies2}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={user?.occuGroup?.studies}
                                                                />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="f-col">
                                                            <Form.Group controlId="mainsubject" as={Col}>
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.mainsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    onChange={this.onChange}
                                                                    placeholder={user?.mainsubject}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="secondsubject" as={Col}>
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.secondsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    onChange={this.onChange}
                                                                    placeholder={user?.secondsubject}
                                                                />
                                                            </Form.Group>
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Row className="f-col">
                                                            <Form.Group controlId="occugroup" as={Col}>
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.studyingTo}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    as="textarea"
                                                                    className="custom-control"
                                                                    placeholder={
                                                                        user.comingqualifications
                                                                            ? user.comingqualifications.map(
                                                                                (qualification, index) =>
                                                                                    (index ? " " : "") +
                                                                                    (language.fi
                                                                                        ? qualification.name_fi
                                                                                        : qualification.name_swe)
                                                                            )
                                                                            : "-"
                                                                    }
                                                                />
                                                                {/* <Form.Control disabled className="custom-control" placeholder={user?.education} /> */}
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="f-col">
                                                            <Form.Group controlId="refs" as={Col}>
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.studyyear}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={user?.occuGroup?.studyYear}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="refs" as={Col}>
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.studypoints}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={user?.occuGroup?.points}
                                                                />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="f-col">
                                                            <Form.Group controlId="mainsubject" as={Col}>
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.mainsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    onChange={this.onChange}
                                                                    placeholder={user?.mainsubject}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="secondsubject" as={Col}>
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.secondsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    onChange={this.onChange}
                                                                    placeholder={user?.secondsubject}
                                                                />
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="f-col">
                                                            <Form.Group controlId="education" as={Col}>
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.studies}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={user?.occuGroup?.studies}
                                                                />
                                                            </Form.Group>
                                                            {(user?.status === "Studerande" ||
                                                                user?.status === "Opiskelija") && (
                                                                <Form.Group controlId="types" as={Col}>
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.qualifications_student}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        disabled
                                                                        as="textarea"
                                                                        className="custom-control"
                                                                        placeholder={user?.qualifications?.map(
                                                                            (qualification, index) =>
                                                                                (index ? " " : "") +
                                                                                (language.fi
                                                                                    ? qualification.name_fi
                                                                                    : qualification.name_swe)
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                            )}
                                                        </Row>
                                                    </>
                                                )}
                                                <Row className="f-col">
                                                    <Form.Group controlId="refs" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.eduexp}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.experience}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="f-col">
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.nativelang}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.nativeLanguage}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.languages}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.languages?.map(
                                                                (language, index) =>
                                                                    (index ? " " : "") + language.name
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                {/* <Row className="f-col">
                                                  <Form.Group as={Col}>
                                                      <Form.Label className="text-muted lt cBold">{language.age}</Form.Label>
                                                      <Form.Control disabled className="custom-control" placeholder={user?.age ? user?.age : '-'} />
                                                  </Form.Group>
                                              </Row> */}
                                            </Card.Body>
                                        </Col>
                                        <Col>
                                            <Card.Body className="mt-3">
                                                <Card.Title>
                                                    <h4>
                                                        {language.fi ? "Yhteystiedot" : "Kontaktuppgifter"}
                                                    </h4>
                                                </Card.Title>
                                                <Row className="f-col">
                                                    <Form.Group controlId="email" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.email}
                                                        </Form.Label>
                                                        <a href={`mailto:${user?.email}`}>
                                                            <Form.Control
                                                                style={{cursor: "pointer"}}
                                                                disabled
                                                                className="custom-control"
                                                                placeholder={user?.email}
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                    <Form.Group controlId="phone" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.phone}
                                                        </Form.Label>
                                                        <a href={`tel:${user?.phone}`}>
                                                            <Form.Control
                                                                disabled
                                                                style={{cursor: "pointer"}}
                                                                className="custom-control"
                                                                placeholder={user?.phone}
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="f-col">
                                                    <Form.Group controlId="phone" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.address}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.address}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                {/* <Row className="f-col">
                                                  <Form.Group as={Col}>
                                                      <Form.Label className="text-muted lt cBold">{language.regiontext}</Form.Label>
                                                      <Form.Control as="textarea" disabled className="custom-control" placeholder={user?.regions ? user?.regions.map((region, index) =>
                                                          (index ? ' ' : '') + region.name_swe
                                                      ) : '-'} />
                                                  </Form.Group>
                                              </Row> */}
                                                <Row>
                                                    <Form.Group as={Col} xs="7">
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.profilepic}
                                                        </Form.Label>
                                                        {/* <Image className="profile-img" src={imgUrl + user?.image} /> */}
                                                        <div className="image-preview">
                                                            <Image
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    borderRadius: "10px",
                                                                }}
                                                                src={
                                                                    user?.image
                                                                        ? imgUrl + user?.image
                                                                        : DefaultAvatar
                                                                }
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="f-col">
                                                    <Form.Group controlId="age" as={Col}>
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.fi ? "Syntymävuosi" : "Födelseår"}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={user?.age}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="f-col">
                                                    <Col>{this.buttonVariant()}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Container>
                    ) : (
                        <div className="spin-wrapper">
                            <Spinner size="8x" spinning={"spinning"}/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

ViewUserProfile.propTypes = {
    logoutTeacher: PropTypes.func.isRequired,
    updateJobFiles: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    addChosen: PropTypes.func.isRequired,
    removeChosen: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    logoutTeacher,
    addChosen,
    deleteFile,
    updateJobFiles,
    removeChosen,
})(withRouter(withAlert()(ViewUserProfile)));
