import React from "react";
import { Button, Modal } from "react-bootstrap";
import { default as CloseIcon } from "../users-only/icons/close_purple.svg";

const EditProfileModal = (props) => {
  const { isOpen, handleCloseChange, language } = props;
  return language.sv ? (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={true}
      onHide={handleCloseChange}
      dialogClassName="apply-consent-modal"
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>
          Slutför din registrering!
        </Modal.Title>
        <button
          type="button"
          className="close"
          onClick={handleCloseChange}
          style={{ opacity: "1" }}
        >
          <span aria-hidden="true">
            <img
              src={CloseIcon}
              alt=""
              className="errorApplyCloseBtn"
              style={{ width: "1.3em" }}
            />
          </span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Välj <b>Profil {"->"} Tilläggsinformation</b> och komplettera
          informationen i din profil.
        </p>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Tilläggsuppgifterna ger arbetsgivaren en möjlighet att lära känna dig
          bättre och ökar dina möjligheter att bli anställd.
        </p>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          När du slutfört registreringen stängs denna påminnelse av.
        </p>
      </Modal.Body>
      <Modal.Footer className="px-0 py-4">
        <Button variant="custom" block onClick={handleCloseChange}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={true}
      onHide={handleCloseChange}
      dialogClassName="apply-consent-modal"
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>
          Saata loppuun rekisteröitymisesi!
        </Modal.Title>
        <button
          type="button"
          className="close"
          onClick={handleCloseChange}
          style={{ opacity: "1" }}
        >
          <span aria-hidden="true">
            <img
              src={CloseIcon}
              alt=""
              className="errorApplyCloseBtn"
              style={{ width: "1.3em" }}
            />
          </span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Valitse <b>Profiili {"->"} Lisätiedot</b> ja täydennä profiilisi
          tiedot.
        </p>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Lisätietojen avulla työnantaja voi tutustua sinuun paremmin,
          työllistymistäsi edesauttaen.
        </p>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Tämä muistutus poistuu kun olet saattanut rekisteröitymisesi loppuun.
        </p>
      </Modal.Body>
      <Modal.Footer className="px-0 py-4">
        <Button variant="custom" block onClick={handleCloseChange}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProfileModal;
