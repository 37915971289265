let swedishMunicipalities = [
    {
        name_swe: "Strängnäs",
        namefi: "Strängnäs",
        name_eng: "Strängnäs",
    },
    /*{
        name_swe: "Nykvarn",
        namefi: "Nykvarn",
        name_eng: "Nykvarn",
    },
    {
        name_swe: "Södertälje",
        namefi: "Södertälje",
        name_eng: "Södertälje",
    },
    {
        name_swe: "Eskilstuna",
        namefi: "Eskilstuna",
        name_eng: "Eskilstuna",
    },*/
]
export default swedishMunicipalities;