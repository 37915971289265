import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {logoutTeacher, passwordReset} from "../../actions/authActions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../styles/forgotPassword.css";
import logo from "../../images/logo.png";
import MobileLayout from "../mobile/mobileLayout";
import Spinner from "../func-components/spinner";
import CustomNavbar from "../navigation/customNavbar";
import {default as Edit} from "../navigation/icons/edit_post.svg";
import Cookies from "js-cookie";
import {toast, ToastContainer} from "react-toastify";
import Backbar from "../mobile/backbar";
import {withAlert} from "react-alert";
import LanguagePicker from "../func-components/languagePicker";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            responseMsg: "",
            errors: {},
            isLoading: false,
            sidebar: !window.matchMedia("(max-width: 1450px)").matches,
            mobile: false,
            language: Cookies.getJSON("_lang") ? Cookies.getJSON("_lang") : "SV",
        };
    }

    componentDidMount() {
        const userLang = this.props.auth.teacher.siteLang;
        if (userLang) {
            this.setState({language: userLang === 2 ? "sv" : "fi"});
        }
        const handler = () => {
            let match = window.matchMedia("(max-width: 600px)").matches;
            if (match) {
                this.setState({mobile: true});
            } else {
                this.setState({mobile: false});
            }
        };
        handler();
        window.addEventListener("resize", handler);
    }

    showSidebar = () => {
        const {sidebar} = this.state;
        if (sidebar) {
            this.setState({sidebar: false});
        } else if (!sidebar) {
            this.setState({sidebar: true});
        }
    };

    goBack = () => {
        this.props.history.goBack();
    };

    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    };

    handleLanguage = (e) => {
        this.setState({language: e.currentTarget.id}, () => {
            Cookies.set("_lang", this.state.language);
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const alert = this.props.alert;
        if (
            this.props.isAuthenticated &&
            this.state.email !== this?.props?.auth?.teacher?.email?.toLowerCase()
        ) {
            toast.error(
                <p style={{fontSize: "1.8em"}}>
                    E-posten stämmer inte överens med den du använder till ditt konto!
                </p>,
                {
                    toastId: "wrongemail",
                    onClick: () => console.log("close"),
                    autoClose: false,
                }
            );
        } else {
            e.preventDefault();
            this.setState({isLoading: true}, () => {
                const emailData = {
                    email: this.state.email,
                };
                this.props.passwordReset(emailData);
            });
            this.setState({isLoading: false});
            let info;
            if (this.state.language === "fi") {
                info = (
                    <p style={{fontSize: "1.8em"}}>
                        Voit nyt uusia salasanasi sähköpostissasi.
                    </p>
                );
            } else {
                info = (
                    <p style={{fontSize: "1.8em"}}>
                        Du kan nu förnya ditt lösenord via din e-post.
                    </p>
                );
            }
            toast.success(info, {
                toastId: "emailsent", // Prevent duplicate toasts
                onClick: () => console.log("close"), // Closes windows on click
                autoClose: false, // Prevents toast from auto closing
            });
            setTimeout(() => {
                this.props.history.push("/login");
            }, 3000);
        }
    };

    render() {
        const {errors, isLoading, sidebar, mobile, language} = this.state;
        return mobile ? (
            <div className="show-body">
                <ToastContainer
                    position="top-center"
                    hideProgressBar={false}
                    closeOnClick
                    draggable
                />
                {this.props.auth.isAuthenticated && (
                    <CustomNavbar
                        userType={this.props.auth.teacher.typeOfUser}
                        sidebar={sidebar}
                        showSidebar={this.showSidebar}
                        page="Inställningar"
                        user={this.props.auth.teacher}
                        logoutTeacher={() => this.props.logoutTeacher()}
                        isAuth={this.props.auth.isAuthenticated}
                    />
                )}
                <div className="main">
                    <Container
                        fluid
                        style={{paddingLeft: "0", paddingRight: "0", minHeight: "100%"}}
                    >
                        <MobileLayout
                            text={language === "fi" ? "Vaihda salasana" : "Byt lösenord"}
                            icon={Edit}
                            renderCard={false}
                        />
                        <Card
                            className="align-middle user-card long-card"
                            style={{
                                transform: "translateY(-18vw)",
                                marginLeft: "1em",
                                marginRight: "1em",
                            }}
                        >
                            <Backbar
                                padding={true}
                                sticky={mobile}
                                desktop={!mobile}
                                goback={this.goBack}
                                title={language === "fi" ? "Takaisin" : "Tillbaka"}
                            />
                            <Card.Body>
                                <Card.Title className="text-muted">
                                    {language === "fi"
                                        ? "Lähetä sähköpostiin"
                                        : "Skicka till e-post"}
                                </Card.Title>
                                <Form noValidate onSubmit={this.onSubmit}>
                                    <Row className="f-col">
                                        <Form.Group as={Col}>
                                            <Form.Control
                                                size="lg"
                                                className="custom-control"
                                                placeholder={
                                                    language === "fi" ? "Sähköposti" : "E-post"
                                                }
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                errors={errors.email}
                                                type="email"
                                                id="email"
                                            />
                                            <span className="red-text">
                        {errors.email}
                                                {errors.emailnotfound}
                      </span>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Button
                                            variant="custom"
                                            className="mr-2 ml-2"
                                            size="lg"
                                            block
                                            type="submit"
                                        >
                                            {language === "fi" ? "Lähetä" : "Skicka"}
                                        </Button>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
        ) : (
            <div className="body">
                <ToastContainer
                    position="top-center"
                    hideProgressBar={false}
                    closeOnClick
                    draggable
                />
                {!isLoading ? (
                    <Container className="vertical-center">
                        <Row className="justify-content-sm-center">
                            <img src={logo} alt="Logo" className="mr-3"/>
                        </Row>
                        <Row className="justify-content-sm-center mt-3">
                            <LanguagePicker
                                handleLang={this.handleLanguage}
                                language={language}
                            />
                        </Row>
                        <Form noValidate onSubmit={this.onSubmit}>
                            <Row className="justify-content-sm-center mt-3">
                                <Col xs="4">
                                    <Form.Group>
                                        <Form.Control
                                            size="lg"
                                            onChange={this.onChange}
                                            value={this.state.email}
                                            errors={errors.email}
                                            id="email"
                                            type="email"
                                            placeholder={language === "fi" ? "Sähköposti" : "E-post"}
                                        />
                                        <span className="red-text">
                      {errors.email}
                                            {errors.emailnotfound}
                    </span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-sm-center mt-3">
                                <Col xs="4">
                                    <Button variant="custom" size="lg" block type="submit">
                                        {language === "fi" ? "Vaihda salasana" : "Byt lösenord"}
                                    </Button>
                                </Col>
                            </Row>
                            <hr className="mt-5"></hr>
                        </Form>
                    </Container>
                ) : (
                    <div className="spin-wrapper">
                        <Spinner size="8x" spinning={"spinning"}/>
                    </div>
                )}
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    passwordReset: PropTypes.func.isRequired,
    logoutTeacher: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {passwordReset, logoutTeacher})(
    withAlert()(ForgotPassword)
);
