import { hide } from "@popperjs/core";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { default as CloseIcon } from "./icons/close_purple.svg";
import { default as CloseIconWhite } from "./icons/close_white.svg";
import { default as OBSIcon } from "./icons/obs_white.svg";

const ApplyModal = (props) => {
  const {
    job,
    handleApply,
    isOpen,
    handleCloseChange,
    text,
    errormsg,
    language,
    confirmApply,
  } = props;
  return (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={true}
      onHide={handleCloseChange}
      dialogClassName={
        errormsg ? "apply-consent-modal-error" : "apply-consent-modal"
      }
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>
          {errormsg ? (
            <img
              src={OBSIcon}
              style={{ width: "0.5em" }}
              alt="exclamation point"
            />
          ) : (
            language.beforeapply
          )}
        </Modal.Title>
        <button
          type="button"
          className="close"
          onClick={handleCloseChange}
          style={{ opacity: "1" }}
        >
          <span aria-hidden="true">
            <img
              src={errormsg ? CloseIconWhite : CloseIcon}
              alt=""
              className="errorApplyCloseBtn"
              style={{ width: "1.3em" }}
            />
          </span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p
          style={{
            fontSize: "1.6em",
            fontWeight: "500",
            color: errormsg ? "white" : "",
          }}
        >
          {language.fi ? text.fi : text.sv}
        </p>
      </Modal.Body>
      <Modal.Footer className="px-0 py-4">
        {errormsg ? (
          <Button
            variant="customModalError"
            block
            onClick={confirmApply}
            id={job.uniqueId}
          >
            {language.fi ? "Minä ehdin!" : "Jag hinner!"}
          </Button>
        ) : (
          <Button
            variant="custom"
            block
            onClick={handleApply}
            id={job.uniqueId}
          >
            {language.applyconsent}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ApplyModal;
