import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {registerTeacher} from "../../actions/authActions";
import classnames from "classnames";
import {Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import "../../styles/body-verticalCenter.css";
import logo from "../../images/logo.png";
import LanguagePicker from "../func-components/languagePicker";
import Cookies from "js-cookie";
import {ToastContainer} from "react-toastify";
import TeacherRegSuccess from "../func-components/teacherRegSuccess";

class TeacherReg extends Component {
    constructor() {
        super();
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            confirmEmail: "",
            phone: "",
            school: "",
            password: "",
            password2: "",
            errors: {},
            language: Cookies.getJSON("_lang") ? Cookies.getJSON("_lang") : "SV",
            regSuccess: false,
            isOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
        if (!this.props.location.state) {
            this.props.history.push("/register/securitycode");
        } else {
            const {school} = this.props.location.state;
            this.setState({school: school.name}, () => {
                this.props.history.replace();
            });
        }
    }

    /* componentWillUnmount(){
          if(this.props.location.state){
              this.props.history.replace()
          }
      } */

    //TODO: apparently deprecated? look into replacement
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }
    }

    handleCloseChange = ({isOpen}) => {
        this.setState({isOpen: false});
    };

    handleLanguage = (e) => {
        this.setState({language: e.currentTarget.id}, () => {
            Cookies.set("_lang", this.state.language);
        });
    };

    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    };

    onSubmit = (e) => {
        e.preventDefault();

        const newTeacher = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            confirmEmail: this.state.confirmEmail,
            phone: this.state.phone,
            school: this.state.school,
            password: this.state.password,
            password2: this.state.password2,
        };
        let promise = this.props.registerTeacher(newTeacher, this.props.history);
        promise.then((data) => {
            console.log("data: ", data);
            if (!data?.success || data === "undefined") {
                console.log("Reg Error: ", this.state.email);
            } else {
                this.setState({regSuccess: true, isOpen: true}, () => {
                    /* let toastMsg = this.state.language === "fi" ?
                                  <div><b>Rekisteröityminen onnistui.</b><br/> Sinut siirretään automaattisesti kirjautumiseen.<br/> Jos tämä ei tapahdu, <Link className='forgot-link' to="/login">paina TÄSTÄ.</Link></div>
                                  :
                                  <div><b>Registreringen lyckades.</b> <br/> Du flyttas automatiskt till inloggningen.<br/> Om inte, <Link className='forgot-link' to="/login">klicka HÄR.</Link></div>
                              toast.success(toastMsg, {
                                  toastId: "supportsent",
                                  autoClose: false,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light"
                              }) */
                    this.timeOut();
                });
            }
        });
        /* this.props.registerTeacher(newTeacher, this.props.history); */
    };

    timeOut = () => {
        setTimeout(() => {
            this.props.history.push("/login");
        }, 3000);
    };

    render() {
        const {errors, language, isOpen, confirmEmail} = this.state;
        return (
            <div className="body">
                <Container>
                    {this.state.regSuccess && (
                        <TeacherRegSuccess
                            isOpen={isOpen}
                            handleCloseChange={this.handleCloseChange}
                            language={this.state.language}
                        />
                    )}
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{fontSize: "1.8em", fontWeight: "600"}}
                    />
                    <Row className="justify-content-md-center mt-3 text-center">
                        <Col md={4}>
                            <Image fluid src={logo} alt="Logo" className=""/>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-3 text-center">
                        <Col md={4}>
                            <h2>{this.state.school}</h2>
                            <div className="mb-2">
                                <LanguagePicker
                                    language={language}
                                    handleLang={this.handleLanguage}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Form noValidate onSubmit={this.onSubmit}>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md={4}>
                                <Form.Group controlId="firstName">
                                    <Form.Control
                                        type="text"
                                        placeholder={language === "fi" ? "Etunimi" : "Förnamn"}
                                        onChange={this.onChange}
                                        value={this.state.firstName}
                                        className={classnames("", {
                                            invalid: errors.firstName,
                                        })}
                                    />
                                    <Form.Text className="text-danger st">
                                        {errors.firstName}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md={4}>
                                <Form.Group controlId="lastName">
                                    <Form.Control
                                        type="text"
                                        placeholder={language === "fi" ? "Sukunimi" : "Efternamn"}
                                        onChange={this.onChange}
                                        value={this.state.lastName}
                                        className={classnames("", {
                                            invalid: errors.lastName,
                                        })}
                                    />
                                    <Form.Text className="text-danger st">
                                        {errors.lastName}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md={4}>
                                <Form.Group controlId="phone">
                                    <Form.Control
                                        type="tel"
                                        placeholder={language === "fi" ? "Puhelin" : "Telefon"}
                                        onChange={this.onChange}
                                        value={this.state.phone}
                                        className={classnames("", {
                                            invalid: errors.phone,
                                        })}
                                    />
                                    <Form.Text className="text-danger st">
                                        {errors.phone}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md={4}>
                                <Form.Group controlId="email">
                                    <Form.Control
                                        autoCapitalize="off"
                                        type="email"
                                        placeholder={language === "fi" ? "Sähköposti" : "E-post"}
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        className={classnames("", {
                                            invalid: errors.email,
                                        })}
                                    />
                                    <Form.Text className="text-danger st">
                                        {errors.email}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md="4">
                                <Form.Group controlId="confirmEmail">
                                    <Form.Control
                                        autoCapitalize="off"
                                        type="email"
                                        placeholder={
                                            language === "fi"
                                                ? "Vahvista sähköposti"
                                                : "Bekräfta e-post"
                                        }
                                        onChange={this.onChange}
                                        value={this.state.confirmEmail}
                                        className={classnames("", {
                                            invalid: errors.confirmEmail,
                                        })}
                                        onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }}
                                    />
                                    <Form.Text className="text-danger st">
                                        {errors.confirmEmail}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md={4}>
                                <Form.Group controlId="password">
                                    <Form.Control
                                        type="password"
                                        placeholder={language === "fi" ? "Salasana" : "Lösenord"}
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        className={classnames("", {
                                            invalid: errors.password,
                                        })}
                                    />
                                    <Form.Text className="text-danger st">
                                        {errors.password}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md={4}>
                                <Form.Group controlId="password2">
                                    <Form.Control
                                        type="password"
                                        placeholder={
                                            language === "fi"
                                                ? "Vahvista salasana"
                                                : "Bekräfta lösenord"
                                        }
                                        onChange={this.onChange}
                                        value={this.state.password2}
                                        className={classnames("", {
                                            invalid: errors.password2,
                                        })}
                                    />
                                    <Form.Text className="text-danger st">
                                        {errors.password2}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3 text-center">
                            <Col md={4}>
                                <Button
                                    variant="custom"
                                    block
                                    size="lg"
                                    className="mb-2"
                                    type="submit"
                                >
                                    {language === "fi" ? "Rekisteröidy" : "Registrera dig"}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center my-3">
                            <Col md="4">
                                <Button
                                    variant="customDelete-reverse"
                                    onClick={() => this.props.history.push("/login")}
                                    block
                                    size="lg"
                                >
                                    {language === "fi" ? "peruuta" : "avbryt"}
                                </Button>
                                {/* PERUUTA */}
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        );
    }
}

TeacherReg.propTypes = {
    registerTeacher: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {registerTeacher})(
    withRouter(TeacherReg)
);
