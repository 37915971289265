export default [
    {
        question: `Vad är Vikaaria?`,
        answer: `Vikaaria är en unik tjänst som notifierar vikarier direkt du behöver vikarie. Med några snabba klick på skärmen kan du publicera vikariat för att snabbt hitta vikarie. Tjänsten Vikaaria underlättar, sparar tid och resurser genom att skolor i behov av vikarier på ett unikt sätt, snabbt och effektivt, når ut till många vikarier samtidigt.         
        `,
        question_fi: `Mikä Vikaaria on?`,
        answer_fi: `Vikaaria on sovellus, ainutlaatuinen palvelu, joka ilmoittaa sijaisille heti kun arvitset sijaisia. Muutamalla nopealla klikkauksella voit julkaista sijaisuuden löytääksesi nopeasti sijaisen. Vikaaria helpottaa, säästää aikaa ja resursseja siten, että koulut, jotka tarvitsevat sijaisia, tavoittavat ainutlaatuisella tavalla, nopeasti ja tehokkaasti, useita sijaisia samanaikaisesti.   `
    },
    {
        question: `Vem har skapat Vikaaria`,
        answer: `Vi som skapat tjänsten Vikaaria är erfarna pedagoger och företagare inom marknadsföring och programmering samt mediabranschen.`,
        question_fi: `Kuka on luonut Vikaarian?`,
        answer_fi: `Me Vikaaria -palvelun luoneet olemme pitkän kokemuksen omaavia pedagogeja ja yrittäjiä markkinoinnissa ja ohjelmoinnissa sekä media-alalla.`
    },
    {
        question: `Vilken är kostnaden för Vikaaria?`,
        answer: `Kommunen/staden anskaffar en årlig licens. Licensen är skolvis och licenskostnaden beräknas enligt antalet elever i skolan.`,
        question_fi: `Mitä Vikaaria maksaa?  `,
        answer_fi: `Kunta/kaupunki hankkii vuosilisenssin. Lupa on koulukohtainen ja lisenssimaksu lasketaan koulun oppilasmäärän mukaan. `
    },
    {
        question: `Vad har jag/vi för nytta av Vikaaria? `,
        answer: `Det är utmanande och tidskrävande för skolor att söka och nå vikarier. Tjänsten Vikaaria underlättar, sparar tid och resurser genom att skolor i behov av vikarier på ett unikt sätt, snabbt och effektivt, når ut till många vikarier samtidigt. Den tid som särskilt rektorer ägnar åt att söka och nå vikarier kan tack vare Vikaaria läggas på andra uppgifter. Vikariat kan publiceras oavsett tid på dygnet. Lärare eller rektor kan t.ex. redan kvällstid publicera vikariat för att avlasta rektor, som på morgonen kan undgå att akut söka vikarie. För rektor finns ytterligare en skild funktion för brådskande fall. I brådskande fall kan rektor med två klick publicera vikariat försett standardmeddelande. `,
        question_fi: `Mitä minä/me hyödymme Vikaariasta? `,
        answer_fi: `Sijaisten hakeminen ja tavoittaminen on kouluille haastavaa ja aikaa vievää. Vikaaria helpottaa, säästää aikaa ja resursseja siten, että koulut, jotka tarvitsevat sijaisia, tavoittavat ainutlaatuisella tavalla, nopeasti ja tehokkaasti useita sijaisia samanaikaisesti. Erityisesti rehtoreiden, sijaisten hakemiseen ja tavoittamiseen, tarvittava aika voidaan Vikaarian ansiosta käyttää muihin tehtäviin. Sijaisuuden voi julkaista milloin tahansa vuorokauden aikana. Opettaja tai rehtori voi esimerkiksi jo illalla julkaista sijaisuuden. Näin rehtori voi välttää tarpeen aamulla kiireellisesti hakea sijaista. Rehtoreille löytyy lisäksi erillinen toiminto jolla kiireellisissä tapauksissa rehtori voi kahdella klikkauksella pikaisesti julkaista sijaisuuden. Sijaisuus on kuvattu rehtorin määrittämällä standarditekstillä. `
    },
    {
        question: `Var hittar jag appen?`,
        answer: `Vikaaria, som är en progressiv webbapplikation, kan laddas ner från PlayStore eller app.vikaaria.fi.  Du kan även använda Vikaaria på din dator. Öppna Vikaaria här, app.vikaaria.fi. Du hittar länken även på vikaaria.fi -hemsidan. Notera att iPhone i nuläget inte kan ta emot notiser från progressiva webbapplikationer.`,
        question_fi: `Mistä löydän sovelluksen? `,
        answer_fi: `Vikaaria, joka on progressiivinen verkkosovellus, on ladattavissa PlayStoresta tai app.vikaaria.fi:stä. Voit käyttää Vikaariaa myös tietokoneellasi. Avaa Vikaaria tästä, app.vikaaria.fi. Linkin löydät myös vikaaria.fi -sivustolta. Huomaa, että iPhone ei tällä hetkellä voi vastaanottaa ilmoituksia progressiivisista verkkosovelluksista.`
    },
    {
        question: `Hur registrerar jag mig?`,
        answer: `Som rektor registreras du till en början av företaget Listit Software Oy, som förser dig med login -uppgifter. Som lärare registrerar du dig själv eller läggs till av rektor. Registrerar du dig själv behöver du vid första inloggningen uppge en kod, som rektor förser dig med. Koden identifierar din skola och du uppger ditt namn, telefonnummer, e-postadress och skapar lösen. Ifall rektor lägger till dig får du ett e-postmeddelande med länk till inloggningen samt lösen. Vi rekommenderar att du loggar in och ändrar lösen. Vi rekommenderar att redigera profil och ladda upp profilbild.`,
        question_fi: `Kuinka rekisteröidyn? `,
        answer_fi: `Rehtorina sinut rekisteröi aluksi Listit Software Oy, joka toimittaa sinulle kirjautumistiedot. Opettajana joko rekisteröidyt itse tai sinut lisää rehtori. Jos rekisteröidyt itse, sinun on ensimmäisellä kirjautumisella syötettävä koodi, jonka rehtori antaa sinulle. Koodi tunnistaa koulusi, ilmoitat nimesi, puhelinnumerosi, sähköpostiosoitteesi ja luot salasanan. Jos rehtori lisää sinut, saat sähköpostiisi linkin kirjautumiseen ja salasanan. Suosittelemme kirjautumaan sisään ja vaihtamaan salasanan. Suosittelemme muokkaamaan profiilia ja lataamaan profiilikuvan. `
    },
    {
        question: `Hur publicerar jag vikariat?  `,
        answer: `I Skapa vikariat, skapar du ett vikariat genom att ange tidpunkt, yrkesgrupp för vilken vikarie sökes, årskurs och vilka läroämnen vikariatet gäller. Du kan fritt formulera tilläggsinformation och även bifoga dokument. Med ett klick publicerar du vikariatet och vikarier notifieras. 
        `,
        question_fi: `Miten luon ja julkaisen sijaisuuden?   `,
        answer_fi: `Kohdassa Lisää sijaisuus luot sijaisuuden täyttämällä ajankohta, ammattiryhmä jolle etsitään sijaista, vuosiluokka sekä kyseiset oppiaineet. Voit vapaasti muotoilla lisätietoja ja liittää mukaan asiakirjoja. Klikkaamalla kerran julkaiset sijaisuuden ja sijaisille ilmoitetaan. `
    },
    {
        question: `Är samtliga fält obligatoriska vid skapande av vikariat? `,
        answer: `De flesta fält är obligatoriska. Rektor/lärare kan frivilligt ange orsaken till att vikarie söks. Rektor kan frivilligt ange namnet på personen för vilken vikarie söks. Observera att syftet med dessa två valfria fält är att vid behov underlätta rektors/lärares administration. Observera att informationen i dessa två valfria fält är synlig endast för rektorn/läraren ifråga.`,
        question_fi: `Ovatko kaikki kentät pakollisia sijaisuutta luotaessa?`,
        answer_fi: `Suurin osa kentistä on pakollisia. Rehtori/opettaja voi vapaaehtoisesti ilmoittaa perusteen sijaisen hakemiseen. Rehtori voi vapaaehtoisesti ilmoittaa henkilön nimen, jolle sijaista haetaan. Huomaa, että näiden kahden valinnaisen kentän käyttötarkoitus on tarvittaessa helpottaa rehtorin/opettajan hallintoa. Huomaa, että näiden kahden valinnaisen kentän tiedot näkyvät vain rehtorille/opettajalle.`
    },
    {
        question: `Hur vet jag att vikarier noterat vikariat jag publicerat?`,
        answer: `I webbappen kan du i realtid följa antalet vikarier som noterat vikariat. Som rektor kan du följa skolans samtliga vikariat. Som lärare kan du följa dina egna vikariat. 
        `,
        question_fi: `Mistä tiedän, että julkaisemani sijaisuus on nähty?`,
        answer_fi: `Verkkosovelluksessa voit reaaliajassa seurata montako sijaista on nähnyt julkaisemasi sijaisuudet. Rehtorina voit seurata kaikkia koulun sijaisuuksia. Opettajana voit seurata omia sijaisuuksia.`
    },
    {
        question: `Hur vet jag att vikarie anmält sig till vikariat jag publicerat?`,
        answer: `I Anslagstavla, följer du i realtid anmälningar till vikariat. Vi rekommenderar att du godkänner notiser eftersom du på så vis snabbare uppmärksammas på nya anmälningar. Notera att Vikaaria är en progressiv webbapplikation och att iPhone i nuläget inte kan ta emot notiser från progressiva webbapplikationer. `,
        question_fi: `Mistä tiedän, että julkaisemaani sijaisuuteen on ilmoittautunut sijainen?`,
        answer_fi: `Kohdassa Ilmoitustaulu, voit seurata reaaliajassa, ilmoittautumisia sijaisuuteen. Suosittelemme, että hyväksyt ilmoitukset, sillä näin huomaat nopeammin ilmoittautumiset. Huomaa, että Vikaaria on progressiivinen verkkosovellus ja että iPhone ei tällä hetkellä voi vastaanottaa ilmoituksia progressiivisista verkkosovelluksista. `
    },
    {
        question: `Hur väljer jag vikarie?
        `,
        answer: `I Anslagstavla följer du i realtid de vikarier som anmält sig till vikariat. Du väljer vikarie med ett klick och vikarien notifieras i realtid om valet. Om du innan val av vikarie vill veta mer om vikarierna, kan du snabbt och enkelt navigera i vikariers Profil för att läsa information (kontaktuppgifter, språkkunskaper, studier, examen, arbetserfarenhet) vikarier uppgivit.  Efter val av vikarie är du snarast i kontakt med vikarien för att avtala om praktiska detaljer. Av praktiska skäl har rektor rättigheter att vid behov välja vikarie för lärarnas del. `,
        question_fi: `Miten valitsen sijaisen?`,
        answer_fi: `Ilmoitustaulu -kohdassa seuraat reaaliajassa sijaisuuteen ilmoittatumisia. Valitset sijaisen klikkaamalla kerran ja sijaiselle ilmoitetaan reaaliajassa valinnasta. Jos, ennen sijaisen valintaa, haluat tietää lisää sijaisista, voit nopeasti ja helposti selata sijaisten profiileja ja lukea sijaisen antamat tiedot (yhteystiedot, kielitaito, opinnot, tutkinto, työkokemus). Sijaisen valinnan jälkeen olet mahdollisimman pian yhteydessä sijaiseen sopiaksesi käytännön yksityiskohdista. Käytännön syistä rehtorilla on oikeus tarvittaessa valita opettajalle sijainen. `
    },
    {
        question: `Vad gör jag om vald vikarie annullerar?`,
        answer: `Vikarier kan, precis som rektor/lärare, på grund av vägande skäl behöva annullera. Vikarier informeras från vår sida noga om att annulleringar utan vägande skäl kan ge en negativ bild. Vid eventuell annullering kan du enkelt publicera vikariatet på nytt. 
        `,
        question_fi: `Mitä teen, jos valittu sijainen peruuttaa vastaanotetun sijaisuuden?`,
        answer_fi: `Sijaiset, kuten rehtori/opettaja, saattavat joutua perumaan painavista syistä. Sijaisille tiedotetaan puoleltamme huolellisesti, että ilman painavaa syytä tehdyt peruutukset voivat antaa negatiivisen vaikutelman. Peruutuksen sattuessa voit helposti julkaista sijaisuuden uudelleen.`
    },
    {
        question: `Vad gör jag om jag valt vikarie men behöver annullera? `,
        answer: `Du kan, precis som vikarier, på grund av vägande skäl behöva annullera. Vi vill uppmärksamma dig på att annullering utan vägande skäl kan ge en negativ bild. I det skede du väljer en vikarie avaktiveras vikariens anmälningar till andra vikariat som sammanfaller med ditt vikariat. Det här betyder att när du annullerar har vikarien gått miste om att bli vald till andra vikariat. Om du är osäker på val av vikarie rekommenderar vi att du avvaktar eller avstår ifrån val. Om du valt vikarie och behöver annullera behöver du omedelbart vara i kontakt med vikarien. 
        `,
        question_fi: `Mitä teen, jos olen valinnut sijaisen, mutta minun on peruutettava?`,
        answer_fi: `Voit, kuten sijaisetkin, joutua peruuttamaan painavista syistä. Huomaathan, että peruuttaminen ilman painavaa syytä voi antaa negatiivisen vaikutelman. Vaiheessa, jossa valitset sijaisen, sijaisen ilmoittautumiset muihin sijaisuuksiin, jotka osuvat sijaisuuteesi, poistetaan. Tämä tarkoittaa, että kun peruutat, sijainen on menettänyt mahdollisuutensa tulla valituksi sijaiseksi näihin muihin sijaisuuksiin. Jos olet epävarma sijaisen valinnasta, suosittelemme odottamaan tai pidättäytymään valinnasta. Jos sinun on tarve peruuttaa, suosittelemme ottamaan välittömästi yhteyttä sijaiseen.  `
    },
    {
        question: `Kan jag redigera/radera vikariat jag publicerat? `,
        answer: `Ja. Av praktiska skäl har rektor rättigheter att redigera och radera även vikariat skapat och publicerat av lärare.
        `,
        question_fi: `Voinko muokata/poistaa julkaisemiani sijaisuuksia?`,
        answer_fi: `Kyllä voit. Käytännön syistä rehtorilla on oikeus muokata ja poistaa myös opettajien luomia ja julkaisemia sijaisuuksia.`
    },
    {
        question: `Behöver jag själv radera inaktuella vikariat? `,
        answer: `Nej. Inaktuella vikariat raderas automatiskt efter att 24 timmar passerat tidpunkten för vikariatet. `,
        question_fi: `Pitääkö minun itse poistaa vanhentuneet sijaisuudet?`,
        answer_fi: `Ei. Vanhentuneet sijaisuudet poistetaan automaattisesti, kun sijaisuuden ajankohdasta on kulunut 24 tuntia. `
    },
    {
        question: `Måste appen vara konstant aktiv för att jag inte ska missa vikariers anmälningar? `,
        answer: `Appen fungerar även om du stänger den.`,
        question_fi: `Pitääkö sovelluksen olla jatkuvasti aktiivinen, jotta en missaa sijaisten ilmoittautumisia?`,
        answer_fi: `Sovellus toimii, vaikka suljet sen. `
    },
    {
        question: `Hur uppdaterar jag appen?   `,
        answer: `Appen uppdateras automatiskt.`,
        question_fi: `Miten päivitän sovelluksen? `,
        answer_fi: `Sovellus päivitetään automaattisesti. `
    },
    {
        question: `Hur raderar jag mitt konto ?`,
        answer: `Du kan radera ditt konto i din egen Profil.`,
        question_fi: `Miten poistan tilini?`,
        answer_fi: `Voit poistaa tilisi kohdassa Profiili.`
    },
]