// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import axios from 'axios';
import EventBus from "./utils/EventBus";

const version = 1.008;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
  /* console.log("PROCESS ENV = ", process.env.NODE_ENV) */
  if ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    /* Custom allow other vikaaria origins */
    const dotApp = 'https://app.vikaaria.fi'
    const internalApp = 'https://vikaaria-internal-s536v.ondigitalocean.app'
    
    if (publicUrl.origin !== window.location.origin && window.location.origin !== dotApp && window.location.origin !== internalApp) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      /* const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`; */
      const swUrl = '/service-worker.js'

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    })

    /* const doRegister = () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    }; */

    /* if(config && config.immediate){
      doRegister()
    } else {
      window.addEventListener('load', doRegister)
    } */
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      if (navigator.vendor === 'Apple Computer, Inc.') {
        console.log("SAFARI")
        if (registration.waiting) {
          if (config && config.onUpdate) {
            config.onUpdate(registration)
          }
        }
      }
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://cra.link/PWA.'
              );
              EventBus.dispatch("new-content");


              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

//custom variables
const applicationServerPublicKey = 'BBNMJpDSKeV7jYbuw5nMdvcxJdV91ME9TESb7yE8cDRJ2OmKa4LmrzmwZvNGDWp8WM59hw5RetBV5eE6y8tNOHU';
const pushButton = document.querySelector('.js-push-btn');
let isSubscribed = false;
let swRegistration = null;
//custom functions
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updateSubscriptionOnServer(subscription, email) {
  // TODO: Send subscription to application server

  const subscriptionJson = document.querySelector('.js-subscription-json');
  const subscriptionDetails =
    document.querySelector('.js-subscription-details');

  if (subscription) {
    let data = {
      userSubscription: subscription,
      email: email
    }
    axios.post("/api/shared/subscription", data)
      .then(res => {
        alert(res.data.msg)
      }).catch(err => console.log(err))
    subscriptionJson.textContent = JSON.stringify(subscription);
    subscriptionDetails.classList.remove('is-invisible');
  } else {
    let data = {
      userSubscription: null,
      email: email
    }
    axios.post("/api/shared/subscription", data)
      .then(res => {
        alert(res.data.msg)
      }).catch(err => console.log(err))
    subscriptionDetails.classList.add('is-invisible');
  }
}

function updateExsistingSubscription(subscription, email) {
  console.log(`Updating push subscription for ${email}`)
  let data = {
    userSubscription: subscription,
    email: email
  }
  console.log("Data for push change: ", data)
  axios.post("/api/shared/subscription/onreload", data)
    .then(res => {
      console.log("[ServiceWorker] Subscription updated")
    }).catch(err => console.log(err))
}

export function subscribeUser(email, update) {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  console.log("Update: ", update)
  if (update) {
    console.log("Starting to update sub")
    swRegistration.pushManager.getSubscription()
      .then(function (subscription) {
        if (subscription) {
          console.log("Subscription found")
          console.log("email: ", email)
          console.log("sub: ", subscription)
          updateExsistingSubscription(subscription, email)
        } else {
          console.log("No sub found, subscribing user")
          swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
          })
            .then(function (subscription) {
              console.log("Subbed user again")
              console.log("Sub: ", subscription)
              updateExsistingSubscription(subscription, email)
            })
        }
      })
      .catch(err => console.error(err))
  } else {
    swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
      .then(function (subscription) {
        console.log('User is subscribed.');

        /* let obj = JSON.parse(JSON.stringify(subscription))
        navigator.serviceWorker.controller.postMessage({
          action: 'REQUEST_SUBSCRIPTION',
          pushSub: obj
        }) */
        updateSubscriptionOnServer(subscription, email);

        isSubscribed = true;

      })
      .catch(function (err) {
        console.log('Failed to subscribe the user: ', err);
      });
  }
}

function unsubscribeUser(email) {
  swRegistration.pushManager.getSubscription()
    .then(function (subscription) {
      if (subscription) {
        return subscription.unsubscribe();
      }
    })
    .catch(function (error) {
      console.log('Error unsubscribing', error);
    })
    .then(function () {
      updateSubscriptionOnServer(null, email);

      console.log('User is unsubscribed.');
      isSubscribed = false;

    });
}

export function initializeUI(email) {
  if (isSubscribed) {
    unsubscribeUser(email);
  } else {
    subscribeUser(email);
  }

  // Set the initial subscription value
  swRegistration.pushManager.getSubscription()
    .then(function (subscription) {
      isSubscribed = !(subscription === null);

      /* updateSubscriptionOnServer(subscription, email); */

      if (isSubscribed) {
        console.log('User IS subscribed.');
      } else {
        console.log('User is NOT subscribed.');
      }
    });
}
