import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CustomNavbar from "../navigation/customNavbar";
import {
  deleteUserAccount,
  getImage,
  hasEditedProfile,
  logoutTeacher,
  savePic,
  saveProfile,
} from "../../actions/authActions";
import {Button, Card, Col, Container, Form, Row, Tooltip,} from "react-bootstrap";
import axios from "axios";
import "../../styles/sharedProfile.css";
//import MunicipalityList from "../../typeaheadData/muni";
import MunicipalityList from "../../typeaheadData/swedishMunicipalities";
import StudyYearList from "../../typeaheadData/studyYearList";
import LanguageList from "../../typeaheadData/languageList";
import LanguageArray from "../../typeaheadData/languageArray";
import GradeArray from "../../typeaheadData/gradeArray";
import QualificationList from "../../typeaheadData/qualifications";
import StudentQualificationList from "../../typeaheadData/studentQualifications";
import TeacherQualificationList from "../../typeaheadData/qualificationsTeachers";
import {Typeahead} from "react-bootstrap-typeahead";
import "../../styles/profile.css";
import Spinner from "../func-components/spinner";
import "react-image-crop/dist/ReactCrop.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import ProfileForm from "./profileForm";
import fi from "../../texts/fi.json";
import sv from "../../texts/sv.json";
import Sort from "../func-components/sort";
import {withAlert} from "react-alert";

class SharedProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPhoto: null,
            errors: {},
            isEditing: false,
            address: "",
            age: "",
            grades: [],
            education: "",
            firstName: "",
            lastName: "",
            examYear: "",
            nativeLanguage: "",
            studies: "",
            points: "",
            studyFocus: "",
            school: "",
            teacherType: "",
            teacherOther: "",
            phone: "",
            studyYear: [],
            refs: "",
            email: "",
            status: "",
            otherTxt: "",
            other: false,
            othertype: false,
            languages: [],
            regions: [],
            data: [],
            types: [],
            comingQuali: [],
            profileImg: null,
            file: null,
            loadingPhoto: true,
            isLoading: true,
            uploadProgress: null,
            crop: {
                aspect: 1 / 1,
                unit: "%",
                width: 90,
            },
            croppedImageUrl: null,
            croppedImageFile: null,
            sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
            isEnabled: false,
            isOpen: false,
            editBot: false,
            language: {},
            sitelang: 0,
            mainsubject: "",
            secondsubject: "",
            hasEdited: false,
            editProfileIsOpen: false,
        };
        this.inputFileRef = React.createRef();
    }

    componentDidMount() {
        const {teacher} = this.props.auth;
        const data = {email: teacher.email};

        this.getLang(this.props.auth.teacher);

        if (teacher.typeOfUser === "user") {
            axios
                .post("/api/users/get/user", data)
                .then((res) => {
                    this.setState({
                        data: res.data,
                        isLoading: false,
                        firstName: res?.data?.firstName,
                        lastName: res?.data?.lastName,
                        phone: res?.data?.phone,
                        address: res?.data?.address,
                        status: res?.data?.status,
                        education: res?.data?.education,
                        examYear: res?.data?.examinationYear,
                        points: res?.data?.occuGroup?.points,
                        school: res?.data?.occuGroup?.school,
                        studyFocus: res?.data?.occuGroup?.studyFocus,
                        studies: res?.data?.occuGroup?.studies,
                        mainsubject: res?.data?.mainsubject,
                        secondsubject: res?.data?.secondsubject,
                        siteLang: res?.data?.siteLang,
                        refs: res?.data?.experience,
                        types: res?.data?.qualifications,
                        comingQuali: res?.data?.comingqualifications,
                        age: res?.data?.age,
                        studyYear: res?.data?.occuGroup?.studyYear,
                    });
                })
                .catch((err) => console.log(err));
            let promise = this.props.hasEditedProfile({
                email: this.props.auth.teacher.email,
            });
            promise.then((data) => {
                let tempHasEdited = data.hasEdited;
                if (tempHasEdited === "undefined" || tempHasEdited === null) {
                    tempHasEdited = false;
                }
                this.setState({
                    hasEdited: tempHasEdited,
                    editProfileIsOpen: !tempHasEdited,
                });
            });
        } else if (teacher.typeOfUser === "teacher") {
            axios
                .post("/api/teachers/get/teacher", data)
                .then((res) => {
                    this.setState({
                        data: res.data,
                        isLoading: false,
                        email: res?.data?.email,
                        firstName: res?.data?.firstName,
                        lastName: res?.data?.lastName,
                        phone: res?.data?.phone,
                        address: res?.data?.address,
                        status: res?.data?.status,
                        education: res?.data?.education,
                        examYear: res?.data?.examinationYear,
                        points: res?.data?.occuGroup?.points,
                        school: res?.data?.occuGroup?.school,
                        studyFocus: res?.data?.occuGroup?.studyFocus,
                        studies: res?.data?.occuGroup?.studies,
                        mainsubject: res?.data?.mainsubject,
                        secondsubject: res?.data?.secondsubject,
                        siteLang: res?.data?.siteLang,
                        refs: res?.data?.experience,
                        types: res?.data?.qualifications,
                        comingQuali: res?.data?.comingqualifications,
                    });
                })
                .catch((err) => console.log(err));
        } else if (teacher.typeOfUser === "principal") {
            axios.post("/api/principals/get/principal", data).then((res) => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                    firstName: res?.data?.firstName,
                    lastName: res?.data?.lastName,
                    phone: res?.data?.phone,
                    address: res?.data?.address,
                    status: res?.data?.status,
                    education: res?.data?.education,
                    examYear: res?.data?.examinationYear,
                    points: res?.data?.occuGroup?.points,
                    school: res?.data?.occuGroup?.school,
                    studyFocus: res?.data?.occuGroup?.studyFocus,
                    studies: res?.data?.occuGroup?.studies,
                    mainsubject: res?.data?.mainsubject,
                    secondsubject: res?.data?.secondsubject,
                    siteLang: res?.data?.siteLang,
                    refs: res?.data?.experience,
                    types: res?.data?.qualifications,
                    comingQuali: res?.data?.comingqualifications,
                });
            });
        }
    }

    getLang = (user) => {
        if (user.siteLang === 2) {
            this.setState({language: sv});
        } else if (user.siteLang === 1) {
            this.setState({language: fi});
        } else {
            this.setState({language: fi});
        }
    };

    handleDeleteAcc = () => {
        let data = {
            email: this.props.auth.teacher.email,
        };
        if (window.confirm("Är du säker att du vill ta bort ditt konto?")) {
            this.props.deleteUserAccount(data);
            this.props.logoutTeacher();
        } else {
            alert("Ingen ändring");
        }
    };

    handleCloseChange = ({isOpen}) => {
        this.setState({isOpen: false});
    };

    handleEditProfileCloseChange = ({editProfileIsOpen}) => {
        this.setState({editProfileIsOpen: false});
    };

    onImgChange = (e) => {
        this.setState({
            userPhoto: e.target.files[0],
            isOpen: true,
            file: URL.createObjectURL(e.target.files[0]),
        });
    };

    //image cropping
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        console.log(crop);
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                `${this.props.auth.teacher.id}.jpeg`
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                var file = new File([blob], fileName, {type: blob.type});
                this.setState({croppedImageFile: file}, () =>
                    console.log(this.state.croppedImageFile)
                );
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    handleImgUpload = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append(
            "img",
            this.state.croppedImageFile,
            this.state.croppedImageFile.name
        );

        axios
            .post("/api/shared/image", formData, {
                onUploadProgress: (data) => {
                    this.setState({
                        uploadProgress: Math.round((100 * data.loaded) / data.total),
                    });
                },
            })
            .then((res) => {
                this.setState({
                    profileImg: res.data,
                    uploadProgress: null,
                    file: null,
                    croppedImageUrl: null,
                });
                const data = {
                    email: this.props.auth.teacher.email,
                    filename: this.state.profileImg.file.filename,
                };

                this.props.savePic(data);
            })
            .catch((err) => console.log(err));
    };

    handleEdit = () => {
        this.setState({
            isEditing: true,
            firstName: this.state.data?.firstName,
            lastName: this.state.data?.lastName,
            phone: this.state.data?.phone,
            address: this.state.data?.address,
            status: this.state.data?.status,
            education: this.state.data?.education,
            examYear: this.state.data?.examinationYear,
            points: this.state.data?.occuGroup?.points,
            school: this.state.data?.occuGroup?.school,
            studyFocus: this.state.data?.occuGroup?.studyFocus,
            studies: this.state.data?.occuGroup?.studies,
            mainsubject: this.state.data?.mainsubject,
            secondsubject: this.state.data?.secondsubject,
            refs: this.state.data?.experience,
            age: this?.state?.age,
            studyYear: this?.state?.studyYear,
            comingQuali: this?.state?.comingQuali,
        });
    };
    editBottomCard = () => {
        this.setState({
            editBot: true,
            firstName: this.state.data?.firstName,
            lastName: this.state.data?.lastName,
            phone: this.state.data?.phone,
            address: this.state.data?.address,
            status: this.state.data?.status,
            education: this.state.data?.education,
            examYear: this.state.data?.examinationYear,
            points: this.state.data?.occuGroup?.points,
            school: this.state.data?.occuGroup?.school,
            studyFocus: this.state.data?.occuGroup?.studyFocus,
            studies: this.state.data?.occuGroup?.studies,
            mainsubject: this.state.data?.mainsubject,
            secondsubject: this.state.data?.secondsubject,
            refs: this.state.data?.experience,
            age: this?.state?.age,
            studyYear: this?.state?.studyYear,
            comingQuali: this?.state?.comingQuali,
        });
    };
    cancleEditBot = () => {
        this.setState({editBot: false, isEnabled: false});
    };
    handleCancel = () => {
        this.setState({isEditing: false, isEnabled: false});
    };

    onSubmit = (e) => {
        e.preventDefault();

        //OBS!!! store languages as an array of objects, easier in the long run than manipulating a bunch of other variables

        const data = {
            email: this.state.email ? this.state.email : this.state.data.email,
            address:
            this.state.address /* ? this.state.address : this.state.data.address */,
            age: this.state.age /* ? this.state.age : this.state.data.age */,
            education:
            this.state
                .education /* ? this.state.education : this.state.data.education */,
            languages:
                this.state.languages.length > 0
                    ? this.state.languages
                    : this.state.data.languages,
            grade:
                this.state.grades.length > 0
                    ? this.state.grades
                    : this.state.data.grade,
            regions:
                this.state.regions.length > 0
                    ? this.state.regions
                    : this.state.data.regions,
            phone: this.state.phone /* ? this.state.phone : this.state.data.phone */,
            refs: this.state
                .refs /* ? this.state.refs : this.state.data.experience */,
            nativeLanguage: this.state.nativeLanguage
                ? this.state.nativeLanguage
                : this.state.data.nativeLanguage,
            firstName:
            this.state
                .firstName /* ? this.state.firstName : this.state.data.firstName */,
            lastName:
            this.state
                .lastName /* ? this.state.lastName : this.state.data.lastName */,
            examYear:
            this.state
                .examYear /* ? this.state.examYear : this.state.data.examinationYear */,
            studies:
            this.state
                .studies /* ? this.state.studies : this.state.data?.occuGroup?.studies */,
            points:
            this.state
                .points /* ? this.state.points : this.state.data?.occuGroup?.points */,
            studyFocus:
            this.state
                .studyFocus /* ? this.state.studyFocus : this.state.data?.occuGroup?.studyFocus */,
            school:
            this.state
                .school /* ? this.state.school : this.state.data?.occuGroup?.school */,
            teacherType: this.state.teacherType
                ? this.state.teacherType
                : this.state.data.teacherType,
            teacherOther:
            this.state
                .teacherOther /* ? this.state.teacherOther : this.state.data.teacherOther */,
            status:
            this.state.status /* ? this.state.status : this.state.data.status */,
            otherTxt: this.state.otherTxt,
            sitelang: parseInt(this.state.sitelang)
                ? parseInt(this.state.sitelang)
                : this.state.data?.siteLang,
            mainsubject:
            this.state
                .mainsubject /* ? this.state.mainsubject : this.state.data.mainsubject */,
            secondsubject:
            this.state
                .secondsubject /* ? this.state.secondsubject : this.state.data.secondsubject */,
            qualifications: this.state.types,
            studyYear: this.state.studyYear,
            comingqualifications: this.state.comingQuali,
        };
        this.props.saveProfile(data, this.props.history);
    };

    onChange = (e) => {
        if (
            e.target.id === "teacherType" &&
            (e.target.value === "Annan" || e.target.value === "Muu")
        ) {
            this.setState({other: true});
        } else if (e.target.id === "status" && e.target.value !== "Annan") {
            this.setState({other: false});
        }
        if (
            e.target.id === "status" &&
            (e.target.value === "Annan" || e.target.value === "Muu")
        ) {
            this.setState({othertype: true});
        } else if (e.target.id === "status" && e.target.value !== "Annan") {
            this.setState({othertype: false});
        }
        this.setState({[e.target.id]: e.target.value, isEnabled: true});
    };

    handleFileClick = (e) => {
        this.inputFileRef.current.click();
    };

    onLogoutClick = () => {
        this.props.logoutTeacher();
    };

    editTooltip = (props) => {
        return (
            <Tooltip id="accepted-tooltip" {...props}>
                {this.state.language.editprofile}
            </Tooltip>
        );
    };

    obligatoryTooltip = (props) => {
        return (
            <Tooltip id="obligatory-tooltip" {...props}>
                {this.state.language.obligatory}
            </Tooltip>
        );
    };

    showSidebar = () => {
        const {sidebar} = this.state;
        if (sidebar) {
            this.setState({sidebar: false});
        } else if (!sidebar) {
            this.setState({sidebar: true});
        }
    };

    handleDeleteAcc = () => {
        const alert = this.props.alert;
        let data = {
            email: this.props.auth.teacher.email,
        };
        if (
            window.confirm(
                "Är du säker att du vill radera ditt konto? Du kan inte ångra detta."
            )
        ) {
            this.props.deleteUserAccount(data);
            this.props.logoutTeacher();
        } else {
            alert.info("Ingen ändring!");
        }
    };

    render() {
        const {teacher} = this.props.auth;
        const {
            isEditing,
            isEnabled,
            uploadProgress,
            profileImg,
            data,
            isLoading,
            file,
            croppedImageUrl,
            crop,
            sidebar,
            firstName,
            lastName,
            address,
            age,
            education,
            email,
            grades,
            phone,
            isOpen,
            examYear,
            teacherType,
            studies,
            points,
            school,
            studyFocus,
            other,
            teacherOther,
            nativeLanguage,
            editBot,
            language,
            othertype,
            mainsubject,
            secondsubject,
            refs,
            studyYear,
            hasEdited,
            languages,
        } = this.state;
        const imgUrl = `${window.location.origin}/api/shared/image/`;
        let array = new Array(studyYear?.toString() || ".1");

        return (
            <div className={this.props.mobile ? "" : "temp-body"}>
                {!this.props.mobile && (
                    <>
                        <CustomNavbar
                            userType={teacher.typeOfUser}
                            sidebar={sidebar}
                            showSidebar={this.showSidebar}
                            page={language.profile}
                            user={teacher}
                            logoutTeacher={this.onLogoutClick}
                            isAuth={this.props.auth.isAuthenticated}
                        />
                        <Sort
                            header={language.profile}
                            userType={teacher.typeOfUser}
                            school={teacher.school}
                            marginLeft={sidebar ? "215px" : "16px"}
                        />
                        <hr className="custom-hr mb-3"></hr>
                    </>
                )}
                <div
                    className={
                        this.props.mobile ? "" : sidebar ? "main push-right" : "main"
                    }
                >
                    {!isLoading ? (
                        <Container fluid style={{marginBottom: "6rem"}}>
                            {!isEditing && !editBot ? (
                                <>
                                    <ProfileForm
                                        data={data}
                                        teacher={teacher}
                                        imgUrl={imgUrl}
                                        crop={crop}
                                        file={file}
                                        inputFileRef={this.inputFileRef}
                                        onImgChange={this.onImgChange}
                                        onCropChange={this.onCropChange}
                                        onCropComplete={this.onCropComplete}
                                        handleImgUpload={this.handleImgUpload}
                                        handleFileClick={this.handleFileClick}
                                        handleEdit={this.handleEdit}
                                        handleEditBot={this.editBottomCard}
                                        onImageLoaded={this.onImageLoaded}
                                        isOpen={isOpen}
                                        handleCloseChange={this.handleCloseChange}
                                        editTooltip={this.editTooltip}
                                        mobile={this.props.mobile ? this.props.mobile : false}
                                        setRender={this.props.setrender}
                                        language={language}
                                        handleDeleteAcc={this.handleDeleteAcc}
                                        obligatoryTooltip={this.obligatoryTooltip}
                                        hasEdited={hasEdited}
                                        editProfileIsOpen={this.state.editProfileIsOpen}
                                        handleEditProfileCloseChange={
                                            this.handleEditProfileCloseChange
                                        }
                                    />
                                </>
                            ) : (
                                ""
                            )}
                            {isEditing ? (
                                <Card
                                    className={this.props.mobile ? "mb-3" : "mt-3"}
                                    style={{
                                        borderRadius: "10px",
                                        border: "none",
                                        boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                    }}
                                >
                                    <Form noValidate onSubmit={this.onSubmit}>
                                        <Row className="f-col">
                                            <Col>
                                                <Card.Body className="mt-3">
                                                    <Card.Title>
                                                        <h4>{language.basicinfo}</h4>
                                                    </Card.Title>
                                                    <Row className="f-col">
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="firstName">
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.firstname}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="custom-control"
                                                                    onChange={this.onChange}
                                                                    /* placeholder={data.firstName} */ value={
                                                                    firstName
                                                                }
                                                                    type="text"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="lastName">
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.lastname}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="custom-control"
                                                                    onChange={this.onChange}
                                                                    /* placeholder={data.lastName}  */ value={
                                                                    lastName
                                                                }
                                                                    type="text"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {teacher.typeOfUser === "user" ? (
                                                        <Row className="f-col">
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group controlId="age">
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.fi ? "Syntymävuosi" : "Födelseår"}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className="custom-control"
                                                                        onChange={this.onChange}
                                                                        /* placeholder={data.address} */ value={age}
                                                                        type="text"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group controlId="address">
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.address}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className="custom-control"
                                                                        onChange={this.onChange}
                                                                        /* placeholder={data.address} */ value={
                                                                        address
                                                                    }
                                                                        type="text"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <Row className="f-col">
                                                        <Col
                                                            xl={teacher.typeOfUser === "user" ? 12 : 6}
                                                            lg={12}
                                                        >
                                                            <Form.Group controlId="phone">
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.phone}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="custom-control"
                                                                    onChange={this.onChange}
                                                                    value={phone}
                                                                    type="phone"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        {teacher.typeOfUser !== "user" ? (
                                                            <Col xl={6} lg={12}>
                                                                {teacher.typeOfUser !== "user" ? (
                                                                    <Form.Group controlId="grade">
                                                                        <Form.Label className="text-muted lt cBold">
                                                                            {language.grade}
                                                                        </Form.Label>
                                                                        <Typeahead
                                                                            className="subject-picker"
                                                                            id="grade-picker"
                                                                            multiple
                                                                            placeholder={language.oneormore_standard}
                                                                            defaultSelected={data.grade}
                                                                            options={
                                                                                language.fi
                                                                                    ? GradeArray.fi
                                                                                    : GradeArray.sv
                                                                            }
                                                                            onChange={(grades) => {
                                                                                this.setState({
                                                                                    grades: grades.sort(),
                                                                                    isEnabled: true,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                    <Row className="f-col">
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="nativeLanguage">
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.nativelang}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    defaultValue="1"
                                                                    onChange={this.onChange}
                                                                    value={nativeLanguage}
                                                                >
                                                                    <option key="blankChoice" value hidden>
                                                                        {data.nativeLanguage}
                                                                    </option>
                                                                    {LanguageArray.map((language) => (
                                                                        <option>{language}</option>
                                                                    ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        {teacher.typeOfUser === "user" ? (
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group controlId="status">
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.status}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        placeholder="status"
                                                                        defaultValue="1"
                                                                        onChange={this.onChange}
                                                                        value={this.state.status}
                                                                    >
                                                                        <option key="blankChoice" value hidden>
                                                                            {data.status}
                                                                        </option>
                                                                        <option>
                                                                            {language.fi ? "Opettaja" : "Lärare"}
                                                                        </option>
                                                                        <option>
                                                                            {language.fi
                                                                                ? "Opiskelija"
                                                                                : "Studerande"}
                                                                        </option>
                                                                        <option>
                                                                            {language.fi ? "Muu" : "Annan"}
                                                                        </option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                                {othertype && (
                                                                    <Form.Group controlId="otherTxt">
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={
                                                                                language.fi
                                                                                    ? "Muu, mikä?"
                                                                                    : "Annan, vad?"
                                                                            }
                                                                            onChange={this.onChange}
                                                                            value={this.state.otherTxt}
                                                                        />
                                                                    </Form.Group>
                                                                )}
                                                            </Col>
                                                        ) : (
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group>
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.languages}
                                                                    </Form.Label>
                                                                    <Typeahead
                                                                        id="language-search"
                                                                        labelKey={language.fi ? "name_fi" : "name"}
                                                                        multiple
                                                                        options={LanguageList}
                                                                        placeholder="Välj ett eller flera språk..."
                                                                        defaultSelected={data.languages}
                                                                        onChange={(languages) => {
                                                                            this.setState({
                                                                                languages,
                                                                                isEnabled: true,
                                                                            });
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                    <Row className="f-col">
                                                        <Form.Group controlId="sitelang" as={Col}>
                                                            <Form.Label className="text-muted lt cBold">
                                                                {language.appLang}
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                placeholder="sitelang"
                                                                /* defaultValue={data?.siteLang} */
                                                                onChange={this.onChange}
                                                                value={this.state.sitelang}
                                                            >
                                                                <option key="blankChoice" value hidden>
                                                                    {data.siteLang === 2 ? "Svenska" : "Suomi"}
                                                                </option>
                                                                <option value={1}>Suomi</option>
                                                                <option value={2}>Svenska</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        {teacher.typeOfUser === "user" && (
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group>
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.regiontext}
                                                                    </Form.Label>
                                                                    <Typeahead
                                                                        id="region-search"
                                                                        labelKey={
                                                                            language.fi ? "namefi" : "name_swe"
                                                                        }
                                                                        multiple
                                                                        options={MunicipalityList}
                                                                        placeholder="Välj ett eller flera landskap..."
                                                                        defaultSelected={data.regions}
                                                                        onChange={(regions) => {
                                                                            this.setState({
                                                                                regions,
                                                                                isEnabled: true,
                                                                            });
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                    <Row className="f-col">
                                                        <Form.Group as={Col} className="ml-md-auto">
                                                            <Button
                                                                onClick={this.handleCancel}
                                                                className="mr-2"
                                                                variant="saveTeacher red-variant"
                                                                block
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="mr-3"
                                                                    icon={faTimesCircle}
                                                                />
                                                                {language.cancel}
                                                            </Button>
                                                            <Button
                                                                disabled={!isEnabled}
                                                                className="mt-3"
                                                                type="submit"
                                                                variant="saveTeacher"
                                                                block
                                                            >
                                                                <FontAwesomeIcon
                                                                    size="lg"
                                                                    className="mr-2"
                                                                    icon={faSave}
                                                                />
                                                                {language.savechanges}
                                                            </Button>
                                                        </Form.Group>
                                                    </Row>
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            ) : (
                                ""
                            )}
                            {editBot ? (
                                <Card
                                    className={this.props.mobile ? "mb-3" : "mt-3 mb-3"}
                                    style={{
                                        borderRadius: "10px",
                                        border: "none",
                                        boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                    }}
                                >
                                    <Form noValidate onSubmit={this.onSubmit}>
                                        <Row className="f-col">
                                            {teacher.typeOfUser === "user" ? (
                                                <Col>
                                                    <Card.Body>
                                                        <Card.Title>{language.additionalinfo}</Card.Title>
                                                        <Row className="f-col">
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group>
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.languages}
                                                                        {(data?.languages?.length <= 0 ??
                                                                            languages?.length <= 0 ??
                                                                            !hasEdited) && (
                                                                            <span className="obligatory">*</span>
                                                                        )}
                                                                    </Form.Label>
                                                                    <Typeahead
                                                                        id="language-search"
                                                                        className={
                                                                            data?.languages?.length > 0 ??
                                                                            languages?.length > 0 ??
                                                                            hasEdited
                                                                                ? ``
                                                                                : `custom-typeahead`
                                                                        }
                                                                        labelKey={language.fi ? "name_fi" : "name"}
                                                                        multiple
                                                                        options={LanguageList}
                                                                        placeholder=""
                                                                        defaultSelected={data.languages}
                                                                        onChange={(languages) => {
                                                                            this.setState({
                                                                                languages,
                                                                                isEnabled: true,
                                                                            });
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            {data.status === "Studerande" ||
                                                            data.status === "Opiskelija" ? (
                                                                <Form.Group as={Col} controlId="school">
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.schoolprofile}
                                                                        {(!school ?? !hasEdited) && (
                                                                            <span className="obligatory">*</span>
                                                                        )}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className={
                                                                            school ?? hasEdited
                                                                                ? `custom-control`
                                                                                : `custom-control-obligatory`
                                                                        }
                                                                        onChange={this.onChange}
                                                                        /* placeholder={data?.occuGroup?.school} */
                                                                        value={school}
                                                                        type="text"
                                                                    />
                                                                </Form.Group>
                                                            ) : (
                                                                <Col xl={6} lg={12}>
                                                                    <Form.Group controlId="school">
                                                                        <Form.Label className="text-muted lt cBold">
                                                                            {language.schoolprofile}
                                                                            {(!school ?? !hasEdited) && (
                                                                                <span className="obligatory">*</span>
                                                                            )}
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            className={
                                                                                school ?? hasEdited
                                                                                    ? `custom-control`
                                                                                    : `custom-control-obligatory`
                                                                            }
                                                                            onChange={this.onChange}
                                                                            value={school}
                                                                            type="text"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                        {othertype ? (
                                                            <Row className="f-col">
                                                                <Col>
                                                                    <Form.Group controlId="otherTxt">
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={
                                                                                language.fi
                                                                                    ? "Muu, mikä?"
                                                                                    : "Annan, vad?"
                                                                            }
                                                                            onChange={this.onChange}
                                                                            value={this.state.otherTxt}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {data.status !== "Studerande" &&
                                                        data.status !== "Opiskelija" ? (
                                                            <>
                                                                <Row className="f-col">
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="education">
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.degree2}
                                                                                {(!education ?? !hasEdited) && (
                                                                                    <span
                                                                                        className="obligatory">*</span>
                                                                                )}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className={
                                                                                    education ?? hasEdited
                                                                                        ? `custom-control`
                                                                                        : `custom-control-obligatory`
                                                                                }
                                                                                onChange={this.onChange}
                                                                                value={education}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="examYear">
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.yearofdegree}
                                                                                {(!examYear ?? !hasEdited) && (
                                                                                    <span
                                                                                        className="obligatory">*</span>
                                                                                )}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className={
                                                                                    examYear ?? hasEdited
                                                                                        ? `custom-control`
                                                                                        : `custom-control-obligatory`
                                                                                }
                                                                                onChange={this.onChange}
                                                                                /* placeholder={data?.examinationYear} */
                                                                                value={examYear}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="f-col">
                                                                    {/* {(data.status === "Lärare" || data.status === "Opettaja") && ()} */}
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group>
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.qualifications}
                                                                                {(this.state?.types?.length <= 0 ??
                                                                                    !hasEdited) && (
                                                                                    <span
                                                                                        className="obligatory">*</span>
                                                                                )}
                                                                            </Form.Label>
                                                                            {/* (data.status === "Lärare" || data.status === "Opettaja") ? TeacherQualificationList : QualificationList */}
                                                                            <Typeahead
                                                                                id="types-search"
                                                                                className={
                                                                                    this.state?.types?.length > 0 ??
                                                                                    hasEdited
                                                                                        ? ``
                                                                                        : `custom-typeahead`
                                                                                }
                                                                                labelKey={
                                                                                    language.fi ? "name_fi" : "name_swe"
                                                                                }
                                                                                multiple
                                                                                options={TeacherQualificationList}
                                                                                placeholder={language.qualifications}
                                                                                defaultSelected={this.state.types}
                                                                                onChange={(types) => {
                                                                                    this.setState({
                                                                                        types,
                                                                                        isEnabled: true,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>

                                                                    {other && (
                                                                        <Col
                                                                            xl={
                                                                                data.status === "Lärare" &&
                                                                                data.status !== "Opettaja"
                                                                                    ? 6
                                                                                    : 12
                                                                            }
                                                                            lg={12}
                                                                        >
                                                                            <Form.Group controlId="teacherOther">
                                                                                <Form.Label
                                                                                    className="text-muted lt cBold">
                                                                                    {language.otherwhat}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="custom-control"
                                                                                    type="text"
                                                                                    onChange={this.onChange}
                                                                                    value={teacherOther}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    )}
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="studies">
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.studies2}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className="custom-control"
                                                                                onChange={this.onChange}
                                                                                /* placeholder={data?.occuGroup?.studies} */
                                                                                value={studies}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="f-col">
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="mainsubject">
                                                                            <Form.Label
                                                                                className="text-muted lt cBold ">
                                                                                {language.mainsubject}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className="custom-control"
                                                                                onChange={this.onChange}
                                                                                /* placeholder={data?.mainsubject} */
                                                                                value={mainsubject}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="secondsubject">
                                                                            <Form.Label
                                                                                className="text-muted lt cBold ">
                                                                                {language.secondsubject}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className="custom-control"
                                                                                onChange={this.onChange}
                                                                                /*  placeholder={data?.secondsubject} */
                                                                                value={secondsubject}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="f-col">
                                                                    <Form.Group controlId="refs" as={Col}>
                                                                        <Form.Label className="text-muted lt cBold">
                                                                            {language.eduexp}
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={3}
                                                                            value={refs}
                                                                            onChange={this.onChange}
                                                                        />
                                                                    </Form.Group>
                                                                </Row>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Row className="f-col">
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="education">
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.studyingTo}
                                                                                {(this.state?.comingQuali?.length <=
                                                                                    0 ??
                                                                                    !hasEdited) && (
                                                                                    <span
                                                                                        className="obligatory">*</span>
                                                                                )}
                                                                            </Form.Label>
                                                                            <Typeahead
                                                                                id="cq-search"
                                                                                className={
                                                                                    this.state?.comingQuali?.length > 0 ??
                                                                                    hasEdited
                                                                                        ? ``
                                                                                        : `custom-typeahead`
                                                                                }
                                                                                labelKey={
                                                                                    language.fi ? "name_fi" : "name_swe"
                                                                                }
                                                                                multiple
                                                                                options={
                                                                                    data.status === "Studerande" ||
                                                                                    data.status === "Opiskelija"
                                                                                        ? StudentQualificationList
                                                                                        : TeacherQualificationList
                                                                                }
                                                                                placeholder={""}
                                                                                defaultSelected={this.state.comingQuali}
                                                                                onChange={(comingQuali) => {
                                                                                    this.setState({
                                                                                        comingQuali,
                                                                                        isEnabled: true,
                                                                                    });
                                                                                }}
                                                                            />
                                                                            {/* <Form.Control className="custom-control" onChange={this.onChange} value={education} type="text" /> */}
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="studyYear">
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.studyyear}
                                                                                {(this.state?.studyYear?.length <= 0 ??
                                                                                    !hasEdited) && (
                                                                                    <span
                                                                                        className="obligatory">*</span>
                                                                                )}
                                                                            </Form.Label>
                                                                            <Typeahead
                                                                                className={
                                                                                    this?.state?.studyYear?.length > 0 ??
                                                                                    hasEdited
                                                                                        ? ``
                                                                                        : `custom-typeahead-studyingto`
                                                                                }
                                                                                id="year-picker"
                                                                                options={StudyYearList}
                                                                                filterBy={() => true}
                                                                                defaultSelected={
                                                                                    new Array(
                                                                                        this.state?.studyYear?.toString() ||
                                                                                        ""
                                                                                    )
                                                                                }
                                                                                placeholder={language?.studyyear}
                                                                                onChange={(studyYear) => {
                                                                                    this.setState({
                                                                                        studyYear,
                                                                                        isEnabled: true,
                                                                                    });
                                                                                }}
                                                                            />
                                                                            {/* <Form.Control className="custom-control" onChange={this.onChange}  value={points} type="text" /> */}
                                                                        </Form.Group>
                                                                    </Col>
                                                                    {/* NOTE: TODO */}
                                                                </Row>
                                                                <Row className="f-col">
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="mainsubject">
                                                                            <Form.Label
                                                                                className="text-muted lt cBold ">
                                                                                {language.mainsubject}
                                                                                {(!mainsubject ?? !hasEdited) && (
                                                                                    <span
                                                                                        className="obligatory">*</span>
                                                                                )}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className={
                                                                                    mainsubject ?? hasEdited
                                                                                        ? `custom-control`
                                                                                        : `custom-control-obligatory`
                                                                                }
                                                                                onChange={this.onChange}
                                                                                /* placeholder={data?.mainsubject} */
                                                                                value={mainsubject}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="secondsubject">
                                                                            <Form.Label
                                                                                className="text-muted lt cBold ">
                                                                                {language.secondsubject}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className="custom-control"
                                                                                onChange={this.onChange}
                                                                                /* placeholder={data?.secondsubject} */
                                                                                value={secondsubject}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="f-col">
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="studies">
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.studies}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className="custom-control"
                                                                                onChange={this.onChange}
                                                                                /* placeholder={data?.occuGroup?.studies} */
                                                                                value={studies}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xl={6} lg={12}>
                                                                        <Form.Group controlId="points">
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.studypoints}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className="custom-control"
                                                                                onChange={this.onChange}
                                                                                value={points}
                                                                                type="text"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="f-col">
                                                                    <Col /*  xl={6} lg={12} */>
                                                                        <Form.Group>
                                                                            <Form.Label className="text-muted lt cBold">
                                                                                {language.qualifications_student}
                                                                            </Form.Label>
                                                                            <Typeahead
                                                                                id="types-search"
                                                                                labelKey={
                                                                                    language.fi ? "name_fi" : "name_swe"
                                                                                }
                                                                                multiple
                                                                                options={
                                                                                    data.status === "Studerande" ||
                                                                                    data.status === "Opiskelija"
                                                                                        ? QualificationList
                                                                                        : TeacherQualificationList
                                                                                }
                                                                                placeholder={language.qualifications}
                                                                                defaultSelected={this.state.types}
                                                                                onChange={(types) => {
                                                                                    this.setState({
                                                                                        types,
                                                                                        isEnabled: true,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    {other && (
                                                                        <Col /* xl={6} lg={12} */>
                                                                            <Form.Group controlId="teacherOther">
                                                                                <Form.Label
                                                                                    className="text-muted lt cBold">
                                                                                    {language.otherwhat}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="custom-control"
                                                                                    type="text"
                                                                                    onChange={this.onChange}
                                                                                    value={teacherOther}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                                <Row className="f-col">
                                                                    <Form.Group controlId="refs" as={Col}>
                                                                        <Form.Label className="text-muted lt cBold">
                                                                            {language.eduexp}
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={3}
                                                                            value={refs}
                                                                            onChange={this.onChange}
                                                                        />
                                                                    </Form.Group>
                                                                </Row>
                                                            </>
                                                        )}
                                                        <Row className="f-col">
                                                            <Col>
                                                                <Button
                                                                    onClick={this.cancleEditBot}
                                                                    className="mr-2"
                                                                    variant="saveTeacher red-variant"
                                                                    block
                                                                >
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        className="mr-2"
                                                                        icon={faTimesCircle}
                                                                    />
                                                                    {language.cancel}
                                                                </Button>
                                                                <Button
                                                                    disabled={!isEnabled}
                                                                    className="mt-5"
                                                                    type="submit"
                                                                    variant="saveTeacher"
                                                                    block
                                                                >
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        className="mr-2"
                                                                        icon={faSave}
                                                                    />
                                                                    {language.savechanges}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Col>
                                            ) : (
                                                <Col>
                                                    <Card.Body>
                                                        <Card.Title>{language.additionalinfo}</Card.Title>
                                                        <Row className="f-col">
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group controlId="age">
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.age}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className="custom-control"
                                                                        onChange={this.onChange}
                                                                        /* placeholder={data.age} */ value={age}
                                                                        type="number"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xl={6} lg={12}>
                                                                <Form.Group controlId="address">
                                                                    <Form.Label className="text-muted lt cBold">
                                                                        {language.address}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className="custom-control"
                                                                        onChange={this.onChange}
                                                                        /* placeholder={data.address} */ value={
                                                                        address
                                                                    }
                                                                        type="address"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row className="f-col">
                                                            <Col>
                                                                <Button
                                                                    onClick={this.cancleEditBot}
                                                                    className="mr-2"
                                                                    variant="saveTeacher red-variant"
                                                                    block
                                                                >
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        className="mr-2"
                                                                        icon={faTimesCircle}
                                                                    />
                                                                    {language.cancel}
                                                                </Button>
                                                                <Button
                                                                    disabled={!isEnabled}
                                                                    className="mt-3"
                                                                    type="submit"
                                                                    variant="saveTeacher"
                                                                    block
                                                                >
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        className="mr-2"
                                                                        icon={faSave}
                                                                    />
                                                                    {language.savechanges}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Col>
                                            )}
                                        </Row>
                                    </Form>
                                </Card>
                            ) : (
                                ""
                            )}
                        </Container>
                    ) : (
                        <div className="spin-wrapper">
                            <Spinner size="8x" spinning={"spinning"}/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

SharedProfile.propTypes = {
    saveProfile: PropTypes.func.isRequired,
    deleteUserAccount: PropTypes.func.isRequired,
    getImage: PropTypes.func.isRequired,
    savePic: PropTypes.func.isRequired,
    logoutTeacher: PropTypes.func.isRequired,
    hasEditedProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    saveProfile,
    getImage,
    savePic,
    logoutTeacher,
    deleteUserAccount,
    hasEditedProfile,
})(withAlert()(SharedProfile));
