import React from "react";
import {Card, Col} from "react-bootstrap";

const BlockCard = (props) => {
    const {index, job, dates, bell, openJob, chosen, language} = props;
    const getLocation = (address) => {
        try {
            const parts = address.split(",");
            const lastPart = parts[parts.length - 1].trim();
            return lastPart || ""
        } catch (error) {
            console.error(error)
            return "";
        }
    }
    return job.school !== "Admin" ? (
        <Col lg={4} md={6} sm={12} key={index} className="mb-4 mt-4">
            <Card
                className={`long-card ${chosen}`}
                id={job.uniqueId}
                as="a"
                onClick={openJob}
            >
                {bell}
                <Col className="my-auto">
                    <Card.Body className={`no-padding h-100 ${chosen}`}>
                        <Card.Title className={`mb-0 cBold t-lg ${chosen}`}>
                            {job.school}
                        </Card.Title>
                        {job?.address && (
                            <Card.Text
                                className={`mb-0 cReg t-md ${
                                    chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                                } `}>
                                {getLocation(job.address)}
                            </Card.Text>
                        )}
                        <Card.Text
                            className={`mb-0 mt-0 cReg t-md  ${
                                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                            }`}
                        >
                            {dates} | {job.startTime + " - " + job.endTime}
                        </Card.Text>
                        <Card.Text
                            className={`mb-0 cReg t-md ${
                                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                            } `}
                        >
                            {language.fi
                                ? job?.typeOfTemp?.name_fi
                                : job?.typeOfTemp?.name_swe}
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Card>
        </Col>
    ) : job.fromAdminType === "Update" ? (
        <Col lg={4} md={6} sm={12} key={index} className="mb-4 mt-4">
            <Card
                className={`long-card ${chosen}`}
                id={job.uniqueId}
                as="a"
                onClick={openJob}
            >
                {bell}
                <Col className="my-auto">
                    <Card.Body className={`no-padding h-100 ${chosen}`}>
                        <Card.Title className={`mb-0 cBold t-lg ${chosen}`}>
                            Vikaaria
                        </Card.Title>
                        <Card.Text
                            className={`mb-0 mt-0 cReg t-md  ${
                                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                            }`}
                        >
                            {dates}
                        </Card.Text>
                        <Card.Text
                            className={`mb-0 cReg t-md ${
                                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                            } `}
                        >
                            Update
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Card>
        </Col>
    ) : (
        <Col lg={4} md={6} sm={12} key={index} className="mb-4 mt-4">
            <Card
                className={`long-card ${chosen}`}
                id={job.uniqueId}
                as="a"
                onClick={openJob}
            >
                {bell}
                <Col className="my-auto">
                    <Card.Body className={`no-padding h-100 ${chosen}`}>
                        <Card.Title className={`mb-0 cBold t-lg ${chosen}`}>
                            Vikaaria
                        </Card.Title>
                        <Card.Text
                            className={`mb-0 mt-0 cReg t-md  ${
                                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                            }`}
                        >
                            {dates}
                        </Card.Text>
                        <Card.Text
                            className={`mb-0 cReg t-md ${
                                chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                            } `}
                        >
                            Vikaaria tiedottaa/informerar
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Card>
        </Col>
    );
};

export default BlockCard;
