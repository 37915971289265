import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrincipalRegisterRoute = ({component: Component, auth, ...rest}) => (
    <Route
        {...rest}
        render={props =>            
            auth.usedCode === true ? (
                <Component {...props} />
            ) : (
                <Redirect to="/register/securitycode" />
            )
        }
    />
);

PrincipalRegisterRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps) (PrincipalRegisterRoute);