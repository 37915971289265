import React from "react";
import "../offline/offline.css";
import { Image } from "react-bootstrap";
import Logo from "../../images/logo.png";

const Fallback = () => {
  return (
    <div className="offline">
      <div>
        <Image className="mb-5" src={Logo} alt="Vikaaria logo" />
        <div>Something unexpected went wrong. Please reload the page.</div>
        <button onClick={() => window.location.reload(true)}>Reload</button>
      </div>
    </div>
  );
};

export default Fallback;
