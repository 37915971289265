import React, { useMemo } from "react";
import { rememberUser } from "../../actions/authActions";
import { default as Remembered } from "../../images/Bookmark_white.svg";
import { default as NotRemembered } from "../../images/Bookmark_purple_outline.svg";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const RememberUser = ({ rememberUserEmail, email, ...props }) => {
  const { rememberedUsers } = props.auth.teacher;
  const userIsRemembered = () => {
    if (!rememberedUsers) {
      return false;
    }
    return rememberedUsers?.includes(rememberUserEmail);
  };

  const isRemembered = useMemo(
    () => userIsRemembered(),
    [rememberUserEmail, rememberedUsers]
  );

  const handleRememberUser = async () => {
    const response = await props.rememberUser({ rememberUserEmail, email });
    if (!response.success) {
      toast.warn(
        response?.error ?? "Something unexpected went wrong, please try again."
      );
    }
  };
  return (
    <Button
      data-toggle="tooltip"
      title={
        props.auth.teacher.siteLang === 2
          ? "Lägg till som favorit"
          : "Lisää suosikkiin"
      }
      className="ml-auto"
      variant={isRemembered ? "remember" : "no-remember"}
      onClick={handleRememberUser}
    >
      {isRemembered ? (
        <img alt="User remembered" style={{ width: "14px" }} src={Remembered} />
      ) : (
        <img
          alt="User not remembered"
          style={{ width: "14px" }}
          src={NotRemembered}
        />
      )}
    </Button>
  );
};
RememberUser.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  rememberUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { rememberUser })(RememberUser);
