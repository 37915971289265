import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./customNavbar.css";
import axios from "axios";
import { useBreakpoints } from "../func-components/mediaHooks";
import NavbarType from "./navbarType";
import MobileNavbar from "./mobileNavbar";
import { changeUserType } from "../../actions/authActions";

//<nav className={sidebar ? 'nav-menu active' : 'nav-menu'>
//  <ul className="nav-menu"

const CustomNavbar = (props) => {
  const { userType, logoutTeacher, sidebar, showSidebar, page, user, isAuth } =
    props;

  const [applicantCounter, setApplicantCounter] = useState(null);
  const [tempCount, setTempCount] = useState(null);
  const [trigger, setTrigger] = useState(false);

  /* let isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' }) */
  let isSmallScreen = useBreakpoints().collapseNavbar;
  let mobile = useBreakpoints().isMobileFloor;

  useEffect(() => {
    let isCancelled = false;
    if (user.typeOfUser === "principal") {
      const data2 = {
        email: user.email,
      };
      axios
        .post("/api/principals/temps", data2)
        .then((res) => {
          const jobs = res.data.jobs;
          let appliedCount = 0;
          let jobCount = 0;
          jobs?.forEach((job) => {
            appliedCount += job.applicants.length;
            jobCount += 1;
          });
          !isCancelled && setApplicantCounter(appliedCount);
          !isCancelled && setTempCount(jobCount);
        })
        .catch((err) => console.log(err));
    }
    if (user.typeOfUser === "teacher") {
      const data3 = {
        owner: user.id,
      };
      axios
        .post("/api/teachers/gettemp", data3)
        .then((res) => {
          const jobs = res.data;
          let appliedCount = 0;
          let jobCount = 0;
          jobs?.forEach((job) => {
            appliedCount += job.applicants.length;
            jobCount += 1;
          });
          !isCancelled && setApplicantCounter(appliedCount);
          !isCancelled && setTempCount(jobCount);
        })
        .catch((err) => console.log(err));
    }
    if (user.typeOfUser === "user") {
      const data = {
        email: user.email,
      };
      axios
        .post("/api/users/temp/count", data)
        .then((res) => {
          !isCancelled && setApplicantCounter(res.data);
        })
        .catch((err) => console.error("Error trying to get temp count: ", err));
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;

    if (sidebar) {
      if (isSmallScreen && !trigger) {
        !isCancelled && showSidebar();
        !isCancelled && setTrigger(true);
      }
      if (!isSmallScreen && trigger) {
        !isCancelled && showSidebar();
        !isCancelled && setTrigger(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [sidebar]);

  const changeType = (type) => {
    let data = {
      email: user.email,
      type: type,
    };
    props.changeUserType(data);
  };

  if (mobile && isAuth) {
    return <MobileNavbar page={page} user={user} />;
  } else if (isAuth) {
    return (
      <NavbarType
        fontSize="40px"
        linkClass={sidebar ? "menu-bars bars-active" : "menu-bars"}
        title={true}
        showSidebar={showSidebar}
        sidebar={sidebar}
        page={page}
        user={user}
        logoutTeacher={logoutTeacher}
        applicantCounter={applicantCounter}
        tempCount={tempCount}
        mobile={mobile}
        userType={user.typeOfUser}
        changeType={changeType}
      />
    );
  } else {
    return null;
  }
};

CustomNavbar.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  changeUserType: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { changeUserType })(CustomNavbar);
